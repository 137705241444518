import {RoleList} from './roleList';
import {RoleCreate} from './roleCreate';
import {RoleEdit} from './roleEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: RoleList,
  create: RoleCreate,
  edit: RoleEdit,
};
