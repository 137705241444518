import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput, 
  required,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { CustomToolbar } from '../components/shared/CustomToolBar';

export const UserEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();

  if (permissions && !permissions.includes('UPDATE_USERS')) {
    return null;
  }
  const initialValues = (data) => {
    const roles = data.roles || [];
    const initial =  {
      role_ids: roles.map((role) => role.id),
      agent_id: data.agent?.id || null
     };
    return initial; 
   };


  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('user.title')}>
      <SimpleForm initialValues={initialValues} redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="USERS"/>}>
        <TextInput source="id" />
        <TextInput source="firstName" label={translate('user.firstName')} validate={required()}/>
        <TextInput source="lastName" label={translate('user.lastName')} validate={required()}/>
        <BooleanInput source="isActive" label={translate('user.isActive')} validate={required()}/>
        <TextInput source="email" label={translate('user.email')} validate={required()}/>
        <ReferenceArrayInput
          label="user.roles"
          source="role_ids"
          reference="roles"
          perPage={1000}
          validate={required()}
        >
         
          <SelectArrayInput 
             allowEmpty={false}
            optionText="name" />
        </ReferenceArrayInput>
        
        <ReferenceInput
          label={translate('user.agent')}
          source="agent_id"
          reference="agents"
         >
          <SelectInput optionText="alias"  />
        </ReferenceInput>
        
      </SimpleForm>
    </Edit>
  );};
