import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  TextInput,
  required
} from 'react-admin';


export const SettingCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_SETTING')) {
      return null;
    }
    return (
      <Create {...props} title={translate('setting.title')}>
        <SimpleForm redirect="list">
        <TextInput
          source="name"
          label={translate('setting.name')}
          validate={required()}
        />
       
        <TextInput
          source="email"
          label={translate('setting.email')}
          validate={required()}
        />
   
        </SimpleForm>
      </Create>
    );
};
