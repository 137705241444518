import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {  format } from 'date-fns';
import   { useQuery } from 'react-admin';
import { ChipStatus } from './ChipStatus';

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 800,
  },
  current: {
    backgroundColor: '#ddd',
  },
    
  }));

export const BookingTable = (props) => { 
    const classes = useStyles();
    const t = useTranslate();
    const { bookingDate, currentBooking } = props;

    
    const { data: bookingsData } = useQuery({
        type: 'getList',
        resource: 'bookings',
        payload: {
          pagination: { page: 1, perPage: 100 },
          sort: { field: 'bookingStart', order: 'asc' },
          filter: { bookingStart: format(bookingDate, 'yyyy-MM-dd'), currentStatus: ['confirmed', 'waiting'] },
        },
    });

    function showServices(bookingServices) {
        const servicesName =  bookingServices.map((booking)=> booking.service.name);
        return servicesName.join(' - ');
    }

    return (
    <>
    { bookingsData && bookingsData.length > 0  ? <>
    <h2>Listado de turnos del día seleccionado</h2>
    <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="booking table">
                <TableHead>
                    <TableRow>
                        <TableCell>{t('booking.date')}</TableCell>
                        <TableCell>{t('booking.end')}</TableCell>
                        <TableCell align="right">{t('booking.client')}</TableCell>
                        <TableCell align="right">{t('booking.duration')}</TableCell>
                        <TableCell align="right">{t('booking.service')}</TableCell>
                        <TableCell align="right">{t('booking.currentstatus')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {bookingsData &&
                        bookingsData.map((item, index) => (
                            <TableRow key={index} className={ currentBooking === item.bookingStart ?  classes.current : ''}>
                                <TableCell component="th" scope="row">
                                    {format(new Date(item.bookingStart), 'dd-MM-yyyy H:mm')}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {format(new Date(item.bookingEnd), 'dd-MM-yyyy H:mm')}
                                </TableCell>
                                <TableCell align="right">
                                    {item.client.firstName} {item.client.lastName}
                                </TableCell>
                                <TableCell align="right">{item.estimatedDuration}{t('booking.minutes')}</TableCell>
                                <TableCell>{showServices(item.bookingServices)}</TableCell>
                                <TableCell><ChipStatus record={item}/></TableCell>
                            </TableRow>
                        ))}

                </TableBody>
            </Table>
        </TableContainer>
        </>
        : <p>No hay turnos agendados para el día selecionado</p> }
        </>
  
        
    )
} 