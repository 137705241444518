import * as React from 'react';
import { SelectInput, useRecordContext } from 'react-admin';
import { useTranslate } from 'ra-core';
import { STATUS_CHOICE } from './type';


export const SelectStatus = (props) => {

    const translate = useTranslate();
    const optionRenderStatus = choice => translate(choice.label);
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }
 
    function checkChangeStatus(currentStatus, newStatus) {
        const validTransitions = {
          pending: ['confirmed', 'waiting', 'canceled', 'finished'],
          confirmed: ['waiting', 'finished', 'canceled'],
          waiting: ['confirmed', 'canceled', 'finished'],
          finished: [], // No transitions allowed
          canceled: [], // No transitions allowed
        };
    
        return (
          validTransitions[currentStatus] &&
          validTransitions[currentStatus].includes(newStatus)
        );
      }

    const availableStatus = STATUS_CHOICE.filter((choice) => ( choice.id === record.currentStatus || checkChangeStatus(record.currentStatus, choice.id)));

    return (
        <>
           <SelectInput
            source="currentStatus"
            choices={availableStatus}
            optionText={optionRenderStatus}
            allowEmpty={false}
            label={translate('booking.currentstatus')}
          />
        </>
    );
};