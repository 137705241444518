import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  required, email
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const AgentEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_AGENTS')) {
    return null;
  }
  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('agent.title')}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="AGENTS"/>}>
        <TextInput
          source="alias"
          label={translate('agent.alias')}
          validate={required()}
        />
        <TextInput
          source="firstName"
          label={translate('agent.firstName')}
          validate={required()}
        />
        <TextInput
          source="lastName"
          label={translate('agent.lastName')}
          validate={required()}
        />
        <TextInput
          source="phone"
          label={translate('agent.phone')}
          validate={required()}
        />
        <TextInput
          source="email"
          label={translate('agent.email')}
          validate={email()}
        />
         <TextInput
          source="description"
          label={translate('agent.description')}
        />
        <BooleanInput
          source="isActive"
          label={translate('agent.isActive')}
        />
      </SimpleForm>
    </Edit>
  );};
