import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  BooleanField,
  SimpleList,
  TextInput,
  SearchInput,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const ClientsList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_CLIENTS')) {
      return null;
    }
    const postFilters = [
      <SearchInput source="q" alwaysOn />,
      <TextInput
        label={translate('user.firstName')}
        source="firstName"
        defaultValue=""
      />,
      <TextInput
        label={translate('user.lastName')}
        source="lastName"
        defaultValue=""
      />,
      <TextInput
        label={translate('user.phone')}
        source="phone"
        defaultValue=""
      />,
      <TextInput
        label={translate('user.email')}
        source="email"
        defaultValue=""
      />,
    ];

   return (
     <List {...props} title={translate('client.title')} filters={postFilters} 
     actions={<ListActions showFilter={true} permissions={permissions} entity="CLIENTS" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="CLIENTS" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.firstName} ${record.lastName}`}
           secondaryText={(record) => record.phone}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="firstName" label={translate('user.firstName')} />
           <TextField source="lastName" label={translate('user.lastName')} />
           <TextField source="phone" label={translate('user.phone')} />
           <EmailField source="email" label={translate('user.email')} />
           <BooleanField
             source="isActive"
             label={translate('user.isActive')}
             sortable={false}
           />
         </Datagrid>
       )}
     </List>
   );

};