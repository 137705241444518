import {SettingList} from './settingList';
import {SettingCreate} from './settingCreate';
import {SettingEdit} from './settingEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: SettingList,
  create: SettingCreate,
  edit: SettingEdit,
};
