import {NoLaboraldayList} from './nolaboraldayList';
import {NoLaboraldayCreate} from './nolaboraldayCreate';
import {NoLaboraldayEdit} from './nolaboraldayEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: NoLaboraldayList,
  create: NoLaboraldayCreate,
  edit: NoLaboraldayEdit,
};
