import * as React from "react";
import { Card, CardContent, CardHeader } from '@material-ui/core';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { useTranslate, usePermissions} from 'react-admin';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import ScheduleIcon from '@material-ui/icons/Schedule';
import AccountIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    const classes = useStyles();
    const translate = useTranslate();
    const { permissions } = usePermissions();
    return (
       <Card>
        <CardHeader title={`${translate('home.title')}`} />
        <CardContent> 
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <h2>{translate('home.access')}</h2>
            <List dense className={classes.root}>
             { permissions && permissions.includes('VIEW_BOOKING') 
             &&  <Link href="./#/bookings">
                <ListItem button>
                    <ListItemAvatar>
                    <ScheduleIcon/>
                    </ListItemAvatar>
                    <ListItemText id="bookings" primary={`${translate('sidebar.bookings')}`} />
                </ListItem>
              </Link> }
              { permissions && permissions.includes('VIEW_ACCOUNT') 
             && <Link href="./#/bookings">
                <ListItem button>
                    <ListItemAvatar>
                    <AccountIcon/>
                    </ListItemAvatar>
                    <ListItemText id="bookings" primary={`${translate('sidebar.account')}`} />
                </ListItem>
              </Link> 
              }
              { permissions && permissions.includes('VIEW_CLIENTS') 
             && <Link href="./#/clients">
                <ListItem button>
                    <ListItemAvatar>
                    <EmojiPeopleIcon/>
                    </ListItemAvatar>
                    <ListItemText id="clients" primary={`${translate('sidebar.clients')}`} />
                </ListItem>
              </Link> 
              }
            </List>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>

          </Grid>
        </Grid>
        </CardContent>
    </Card>);
};