import spanishMessages from '@blackbox-vision/ra-language-spanish';

const customSpanishMessages = {
  ...spanishMessages,
  sidebar: {
    dashboard: 'Inicio',
    users: 'Usuarios',
    agents: 'Agentes',
    services: 'Servicios',
    paymentmethods: 'Métodos Pago',
    clients: 'Clientes',
    bookings: 'Turnos',
    nolaboralday: 'Días no laborable',
    settings: 'Seteos',
    account: 'Movimientos',
    report: 'Informe',
    roles: 'Roles',
  },
  role: {
    name: 'Nombre',
    description: 'Descripción',
    permissions: 'Permisos',
    title: 'Roles',
  },
  home:{
    title: 'Bienvenido al Panel de Administración',
    access: 'Accesos Rápidos',
  },
  report:{
    title_income: 'Ingresos por Método',
    title_outcome: 'Egresos por Método',
    from: 'Desde',
    to: 'Hasta',
    title_balance: 'Balance',
    last_balance: 'Ultimo Saldo',
    total_income: 'Total Ingresos',
    total_outcome: 'Total Egresos',
    total_interest: 'Total Interés',
    interest: 'Interes',
    difference: 'Saldo',
    title_fixed: 'Costos Fijos',
    title_credit: 'Créditos',
    title_cashout: 'Retiros',
    title_income_list: 'Listado de Ingresos',
    title_outcome_list: 'Listado de Egresos sin catalogar',
    date: 'Fecha',
    amount: 'Monto',
    description: 'Descripción',
    user: 'Usuario',
    total: 'Total',
    month: 'Mes',
    choose_month: 'Seleccione Mes',
    booking: 'Turno',
    method: 'Método'
  },
  login: {
    link_forgot_password: 'Olvide la contraseña',
    email_required: 'Ingrese Email',
    password_required: 'Ingrese contraseña',
    password: 'Contraseña',
    confirm_password: 'Confirme contraseña',
    confirm_password_required: 'Confirme contraseña Requerido',
    btn: {
      send: 'Enviar',
    },
  },
  forgot: {
    email: 'Email',
  },
  user: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    isActive: 'Activo',
    createdAt: 'Creado',
    updatedAt: 'Modificado',
    password: 'Contraseña',
    confirm_password: 'Confirmar Contraseña',
    roles: 'Rol',
    phone: 'Celular',
    title: 'Usuario',
    agent: 'Agente'
  },
  button:{
    save: 'Guardar',
    cancel: 'Cancelar',
  },
  agent: {
    firstName: 'Nombre',
    lastName: 'Apellido',
    email: 'Email',
    alias: 'Alias',
    isActive: 'Activo',
    createdAt: 'Creado',
    updatedAt: 'Modificado',
    phone: 'Celular',
    description: 'Descripción',
    title: 'Agente',
  },
  service: {
    name: 'Nombre',
    price: 'Precio',
    isActive: 'Activo',
    createdAt: 'Creado',
    updatedAt: 'Modificado',
    description: 'Descripción',
    title: 'Servicios',
    duration: 'Duracción',
    agents: 'Agentes',
  },
  paymentMethod: {
    name: 'Nombre',
    isActive: 'Activo',
    createdAt: 'Creado',
    updatedAt: 'Modificado',
    key: 'Key',
    title: 'Método Pago',
    percentage: 'Porcentaje',
    interest: 'Interes',
    amount: 'Monto'
  },
  appBar: {
    english: 'Ingles',
    spanish: 'Español',
  },
  booking: {
    title: 'Turnos',
    client: 'Cliente',
    agent: 'Agente',
    description: 'Descripción',
    duration: 'Duración',
    price: 'Precio',
    start: 'Fecha Turno',
    end: 'Final Turno',
    services: 'Servicios',
    service: 'Servicio',
    services_not_selected: 'Servicios no seleccionados',
    total: 'Total',
    hours: 'Horas',
    minutes: 'Minutos',
    minute: 'Minuto',
    hour: 'Hora',
    and: 'y',
    date: 'Fecha',
    payed: 'Pagado',
    payment: 'Pago',
    currentstatus: 'Estado',
    discount: 'Descuento',
    subtotal: 'Subtotal',
    status: {
      pending: 'Pendiente',
      waiting: 'Esperando',
      confirmed: 'Confirmado',
      canceled: 'Cancelado',
      finished: 'Finalizado',
    },
    totalPayed: 'Total Pagado',
  },
  nolaboralday:{
    title: 'Días no laborables',
    day: 'Día',
    isHoliday: 'Feriado'
  },
  setting:{
    title: 'Seteos',
    name: 'Nombre',
    email: 'Email'
  },
  slot:{
    add_slot: 'Agregar horario',
    day: 'Día',
    from: 'Desde',
    to: 'Hasta',
    button_add: 'Agregar',
    slot_times: 'Listado de horarios'
  },
  day:{
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miercoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sabado',
    sunday: 'Domingo'
  },
  account: {
    title: 'Movimientos',
    amount: 'Monto',
    interest: 'Interés',
    balance: 'Saldo',
    type: 'Tipo',
    description: 'Descripción',
    income:'ingreso',
    outcome: 'egreso',
    user: 'usuario',
    date: 'fecha',
    payment: 'Método pago',
    fixed: 'Costo Fijo',
    credit: 'Crédito',
    cashout: 'Retiro',
    category: 'Categoría',
    impactedAt: 'Fecha Impacto',
    impactedAtHelper: 'Fecha para impactar en el reporte',
    from: 'Desde',
    to: 'Hasta',
    canceled: 'Cancelado'
  },
  client: {
    title: 'Cliente'
  }
  
};
export default customSpanishMessages;
