import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const RoleEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_ROLES')) {
    return null;
  }
  const initialValues = (data) => {
    const permissions = data.permissions || [];
    const initial =  {
      permissions_ids: permissions.map((permission) => permission.id),
     };
    return initial; 
   };

  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('role.title')}>
      <SimpleForm initialValues={initialValues} redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="ROLES"/>}>
      <TextInput
          source="name"
          label={translate('role.name')}
          validate={required()}
        />
        <TextInput
          source="description"
          label={translate('role.description')}
          validate={required()}
        />
        <ReferenceArrayInput
            label="role.permissions"
            source="permissions_ids"
            reference="permissions"
            perPage={'1000'}
          >
            <SelectArrayInput
                optionText="description"
                allowEmpty={false}
              />
          </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );};
