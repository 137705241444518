export const translatedStatus = {
    pending: { label: 'booking.status.pending', color: 'pending'},
    confirmed: { label: 'booking.status.confirmed', color: 'confirmed'},
    canceled: { label: 'booking.status.canceled', color: 'canceled'},
    finished: { label: 'booking.status.finished', color: 'finished'},
    waiting: { label: 'booking.status.waiting', color: 'waiting' },  
};

export const STATUS_CHOICE = [
    {
        id: 'pending',
        label: 'booking.status.pending'
    },
    {
        id: 'confirmed',
        label: 'booking.status.confirmed',
    },
    {
        id: 'canceled',
        label: 'booking.status.canceled',
    },
    {
        id: 'finished',
        label: 'booking.status.finished',
    },
    {
        id: 'waiting',
        label: 'booking.status.waiting',
    }
];
