// VENDOR
import React, { useEffect, useState } from 'react';
import { MenuItemLink, useTranslate, useAuthProvider, usePermissions } from 'react-admin';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import UserIcon from '@material-ui/icons/Group';
import RoleIcon from '@material-ui/icons/Group';
import AgentIcon from '@material-ui/icons/Face';
import HomeIcon from '@material-ui/icons/Home';
import ServiceIcon from '@material-ui/icons/Toys';
import PaymentIcon from '@material-ui/icons/Payment';
import SettingsIcon from '@material-ui/icons/Settings';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AccountIcon from '@material-ui/icons/AccountBalance';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    paddingTop: '15px',
    background: 'white',
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

const SideBar = ({ onMenuClick }) => {
  const translate = useTranslate();
  const { loading, permissions } = usePermissions();
  const classes = useStyles();
  const authProvider = useAuthProvider();
  const [menuItems, setMenuItems] = useState([
    {
      route: '',
      text: translate('sidebar.dashboard'),
      icon: <HomeIcon />,
    }
  ]);

  useEffect(() => {
    if (!permissions) return;
    
    const updatedMenuItems = [...menuItems];
   

    if (permissions.includes('VIEW_BOOKING')) {
      updatedMenuItems.push({
        route: 'bookings',
        text: translate('sidebar.bookings'),
        icon: <ScheduleIcon />,
      });
    }
    if (permissions.includes('VIEW_CLIENTS') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'clients',
        text: translate('sidebar.clients'),
        icon: <EmojiPeopleIcon />,
      });
    }

    if (permissions.includes('VIEW_SERVICES')) {
      updatedMenuItems.push({
        route: 'services',
        text: translate('sidebar.services'),
        icon: <ServiceIcon />,
      });
    }

    if (permissions.includes('VIEW_ACCOUNT') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'account',
        text: translate('sidebar.account'),
        icon: <AccountIcon />,
      });
      updatedMenuItems.push({
        route: 'report',
        text: translate('sidebar.report'),
        icon: <AccountIcon />,
      });
    }

    if (permissions.includes('VIEW_NOLABORALDAY')) {
      updatedMenuItems.push({
        route: 'nolaboraldays',
        text: translate('sidebar.nolaboralday'),
        icon: <NotInterestedIcon />,
      });
    }

    if (permissions.includes('VIEW_SETTING') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'settings',
        text: translate('sidebar.settings'),
        icon: <SettingsIcon />,
      });
    }

    if (permissions.includes('VIEW_METHOD')) {
      updatedMenuItems.push({
        route: 'paymentmethods',
        text: translate('sidebar.paymentmethods'),
        icon: <PaymentIcon />,
      });
    }

    if (permissions.includes('VIEW_AGENTS') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'agents',
        text: translate('sidebar.agents'),
        icon: <AgentIcon />,
      });
    }

    if (permissions.includes('VIEW_USERS') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'users',
        text: translate('sidebar.users'),
        icon: <UserIcon />,
      });
    }
    
    if (permissions.includes('VIEW_ROLES') && !permissions.includes('IS_ONLY_AGENT')) {
      updatedMenuItems.push({
        route: 'roles',
        text: translate('sidebar.roles'),
        icon: <RoleIcon />,
      });
    }

    setMenuItems(updatedMenuItems);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, permissions]);


  return (
    <div className={classes.root}>
      {menuItems.map(
        (
          { route, text, icon },
          index,
        ) => {
            return (
              <MenuItemLink
                key={index}
                to={`/${route}`}
                exact={!route}
                primaryText={text}
                leftIcon={icon}
                onClick={onMenuClick}
                className={classes.button}
              />
            );
          })}
      <MenuItemLink
        onClick={() => authProvider.logout()}
        to="/login"
        primaryText={translate('ra.auth.logout')}
        leftIcon={<PowerSettingsNewIcon />}
        className={classes.button}
      />
    </div>
  );
};

export default SideBar;
