import React from 'react';
import {
  Button,
  CardActions,
  CircularProgress,
  Card,
  Box
} from '@material-ui/core';
import { Field, Form } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate, useNotify, useSafeSetState } from 'ra-core';
import userService from '../services/user';
import Input from '../ui/input';


const useStyles = makeStyles(
  (theme) => ({
    '@global': {
      body: {
        backgroundColor: '#382E7E',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        position: 'relative',
      },
    },
    centerBox: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '10%',
      display: 'flex'
    },
    titleBox:{
      padding: '10px'
    },
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'ForgotPassword' },
);

const ForgotPassword = (props) => {
  const [loading, setLoading] = useSafeSetState(false);
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
 

  const validate = (values) => {
    const errors = { email: undefined };
    const EMAIL_REGEX =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape

    if (!values.email) {
      errors.email = translate('ra.validation.required');
    }
    if (!EMAIL_REGEX.test(values.email)){
      errors.email = translate('validation.email');
    }

    return errors;
  };;

  const submit = async (values) => {
    const { email } = values;
    if (email) {
      try {
        setLoading(true);
        await userService.forgot(email.trim());
      } catch (error) {
        notify(error);
      } finally {
        setLoading(false);
      }
    }
  };
  
  return (
    <Box
      sx={{
        maxWidth: 300,
        display: 'inline-block',
        mx: '2px',
        transform: 'scale(0.8)',
      }}
      className={classes.centerBox}
    >
      <Card variant="outlined">
        <p className={classes.titleBox} >Ingrese su email para recibir un correo y recuperar su cuenta.</p>
        <Form
          onSubmit={submit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="email"
                    name="email"
                    component={Input}
                    label={translate('forgot.email')}
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                  fullWidth={true}
                >
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('login.btn.send')}
                </Button>
              </CardActions>
            </form>
          )}
        />
      </Card>
    </Box>
  );
};

export default ForgotPassword;
