import {ServiceList} from './serviceList';
import {ServiceCreate} from './serviceCreate';
import {ServiceEdit} from './serviceEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ServiceList,
  create: ServiceCreate,
  edit: ServiceEdit,
};
