import React, { useState, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import {
  Create,
  SimpleForm,
  RadioButtonGroupInput,
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  FormDataConsumer, 
  DateInput
} from 'react-admin';

const useStyles = makeStyles((theme) => ({
  
  redBackground: {
    backgroundColor: '#ffeeee',
  },
}));


export const AccountCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const classes = useStyles();
    const [outcome, setOutcome] = useState(false);
    const [type, setType] = useState('income'); // State to track data.type

    useEffect(() => {
      setOutcome(type === 'outcome');
    }, [type]);

    if (permissions && !permissions.includes('ADD_ACCOUNT')) {
      return null;
    }

    const initialValues = (data) => {
       return {
        type: 'income',
       };
     };

    
    
    return (
      <Create {...props} title={translate('account.title')}>
        <SimpleForm className={outcome ? classes.redBackground : ''} redirect="list" initialValues={initialValues} >
        <RadioButtonGroupInput source="type"  
          label={translate('account.type')} 
          validate={required()} 
          choices={[
            { id: 'income', name: 'account.income' },
            { id: 'outcome', name: 'account.outcome' },
          ]} 
          onChange={(event, newValue) => {  setType(event)}}/>
        <TextInput
          source="amount"
          label={translate('account.amount')}
          validate={required()}
        />
       
        <TextInput
          source="description"
          label={translate('account.description')}
        />
        <ReferenceInput
            label={translate('account.payment')}
            source="paymentMethod_id"
            reference="paymentmethods"
          >
            <SelectInput optionText="name"  />
          </ReferenceInput>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              return formData.type === 'outcome' ? (
                <SelectInput
                  source="category"
                  choices={[
                    { id: 'cashout', name: 'account.cashout' },
                    { id: 'fixed', name: 'account.fixed' },
                    { id: 'credit', name: 'account.credit' },
                  ]}
                  {...rest}
                />
              ) : null;
            }}
          </FormDataConsumer>
          <DateInput label={translate('account.impactedAt')} source="impactedAt" helperText={translate('account.impactedAtHelper')}/>
        </SimpleForm>
      </Create>
    );
};
