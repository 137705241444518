import {AgentList} from './agentList';
import {AgentCreate} from './agentCreate';
import {AgentEdit} from './agentEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: AgentList,
  create: AgentCreate,
  edit: AgentEdit,
};
