import * as React from 'react';
import { AutocompleteArrayInput, useRecordContext } from 'react-admin';

import { Services } from './servicesTable';

export const ServiceInput = (props) => {
    const { choices, input, onServiceChange, selectedServices, type } = props;
    const record = useRecordContext(props);

    React.useEffect(() => {
        const services = choices.filter((service) => input.value.includes(service.id));

        const newServices = services.map((service) => {
            const selectedService = selectedServices.find((element) => element.service.id === service.id);
            const recordService = record.bookingServices?.find((element) => element.service.id === service.id);
            if (selectedService) { //tengo data modificada en el selectedService
                return { service, duration: service.duration, price: selectedService.price };
            } else if (recordService) {
                return { service, duration: recordService.duration, price: recordService.price || service.price }; // si no tiene en la base un valor pongo el del servicio 
            } else {
                return { service, duration: service.duration, price: service.price };
            }
        });
        if (!arraysEqual(newServices, selectedServices)) {
            onServiceChange(newServices);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ choices, input, onServiceChange, record.bookingServices, selectedServices]);
    
    function objectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
    
        if (keys1.length !== keys2.length) {
            return false;
        }
    
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
            if (val1 !== null && val2 !== null && typeof val1 === 'object' && typeof val2 === 'object') {
                if (!objectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }
    
        return true;
    }

    // Helper function to compare arrays for equality
    const arraysEqual = (a, b) => {
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
       

        for (let i = 0; i < a.length; i++) {
            if (!objectsEqual(a[i], b[i])) {
                return false;
            }
        }

        return true;
    };

    return <Services selectedServices={selectedServices} onServiceChange={onServiceChange} type={type} />;
};

// Render AutocompleteArrayInput separately
export const AutocompleteInputWrapper = (props) => {
    const { onServiceChange, selectedServices, type, ...rest } = props;
    
    return (
        <>
            <AutocompleteArrayInput {...rest} />
            <ServiceInput {...props} />
        </>
    );
};