import {BookingList} from './bookingList';
import {BookingCreate} from './bookingCreate';
import {BookingEdit} from './bookingEdit';
import {BookingShow} from './bookingShow';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: BookingList,
  create: BookingCreate,
  edit: BookingEdit,
  show: BookingShow,
};
