import {ClientsList} from './clientList';
import {ClientCreate} from './clientCreate';
import {ClientEdit} from './clientEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: ClientsList,
  create: ClientCreate,
  edit: ClientEdit
};
