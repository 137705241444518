import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
    Chip
  } from '@material-ui/core';
import { translatedStatus } from './type';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    pending: {
      backgroundColor: '#ff0000', // Cambia esto al color que desees
      color: '#ffffff', // Cambia esto al color que desees para el texto
    },
    waiting: {
      backgroundColor: '#00ff00', // Cambia esto al color que desees
      color: '#000000', // Cambia esto al color que desees para el texto
    },
    confirmed: {
        backgroundColor: '#6640E5', // Cambia esto al color que desees
        color: '#ffffff', // Cambia esto al color que desees para el texto
      },
    canceled: {
        backgroundColor: '#E5A240', // Cambia esto al color que desees
        color: '#000000', // Cambia esto al color que desees para el texto
    },
    finished: {
        backgroundColor: '#90caf9', // Cambia esto al color que desees
        color: '#000000', // Cambia esto al color que desees para el texto
    },

}));

export const ChipStatus = (props) => {
const translate = useTranslate();
const { record } = props;
const classes = useStyles();
if (!record) { 
    return 
}

const status = translatedStatus[record.currentStatus];
    return (
        <>
            <Chip label={translate(status.label)} className={classes[status.color]} />
            
        </>
    );
};