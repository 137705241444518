import * as React from "react";
import {
  Admin,
  Resource,
} from 'react-admin';
import UserContainer from './Users';
import ClientContainer from './components/Clients';
import AgentContainer from './components/Agent';
import SettingContainer from './components/Settings'
import ServiceContainer from './components/Service';
import BookingContainer from './components/Bookings';
import PaymentMethodContainer from "./components/PaymentMethod";
import NolaboraldayContainer from "./components/Nolaboralday";
import RoleContainer from "./components/Role";
import myDataProvider from './dataProvider';
import Dashboard from './dashboard';
import authProvider from './authProvider';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import customEnglishMessages from './i18n/en';
import customSpanishMessages from './i18n/es';
import Layout from './layout';
import customRoutes from "./routes/customRoutes";
import LoginPage from './loginPage';
import { AccountEdit } from "./components/Account/accountEdit";
import { AccountShow } from "./components/Account/accountShow";
import { AccountList } from "./components/Account/accountList";
import { AccountCreate } from "./components/Account/accountCreate";

const messages = {
  es: { ...customSpanishMessages },
  en: { ...customEnglishMessages },
};



const i18nProvider = polyglotI18nProvider(
  (locale) => (messages[locale] ? messages[locale] : messages.es),
  'es',
);


const App = () => (
  <Admin
    title="Lobelia"
    i18nProvider={i18nProvider}
    locale="es"
    dashboard={Dashboard}
    layout={Layout}
    dataProvider={myDataProvider}
    authProvider={authProvider}
    customRoutes={customRoutes}
    loginPage={LoginPage}
    disableTelemetry
  >
    <Resource name="clients" {...ClientContainer} />
    <Resource name="users" {...UserContainer} />
    <Resource name="agents" {...AgentContainer} />
    <Resource name="services" {...ServiceContainer} />
    <Resource name="paymentmethods" {...PaymentMethodContainer} />
    <Resource name="bookings" {...BookingContainer} />
    <Resource name="nolaboraldays" {...NolaboraldayContainer} />
    <Resource name="settings" {...SettingContainer} />
    <Resource name="account" list={AccountList} create={AccountCreate} show={AccountShow} edit={AccountEdit} />
    <Resource name="roles"  {...RoleContainer}/>
    <Resource name="permissions" />
    <Resource name="profile" />
  </Admin>
);

export default App;