import * as React from "react";
import { List, Datagrid, TextField, BooleanField, SimpleList, DateField} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import {  format } from 'date-fns';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const NoLaboraldayList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_NOLABORALDAY')) {
      return null;
    }
    function showDay(data){
      return format(new Date(data), 'dd-MM-yyyy');
    }
   return (
     <List {...props} title={translate('nolaboralday.title')}
     actions={<ListActions showFilter={false} permissions={permissions} entity="NOLABORALDAY" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="NOLABORALDAY" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => showDay(record.day)}
           secondaryText={(record) => record.isHoliday}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <DateField source="day" label={translate('nolaboralday.day')} locales="fr-FR" />
           <BooleanField
             source="isHoliday"
             label={translate('nolaboralday.isHoliday')}
             sortable={false}
           />
         </Datagrid>
       )}
     </List>
   );

};