import { BookingTable } from './bookingTable';

export const BookingTableEdit = (props) => {
    const { startDate, record } = props;

    if (!record) {
        return null;
    }
    const selectedDate = startDate || new Date(record.bookingStart);

    return (
        <BookingTable bookingDate={selectedDate} currentBooking={record.bookingStart} />
    )
}