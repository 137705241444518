import * as React from "react";
import { Show, SimpleShowLayout, TextField, DateField, FunctionField } from 'react-admin';
import { useTranslate } from 'ra-core';
/*import { ChipStatus } from './ChipStatus';
import { EditButton, TopToolbar } from 'react-admin';*/

/*const BookingShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
       { data.currentStatus !== 'finished' && <EditButton basePath={basePath} record={data} /> }
    </TopToolbar>
);*/

export const BookingShow = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('VIEW_BOOKING')) {
        return null;
      }
    return (
    <Show /*actions={<BookingShowActions />}*/  {...props} >
        <SimpleShowLayout>
            <TextField source="agent.alias" label="booking.agent" />
            <FunctionField
                    label='booking.client'
                    render={record => (
                        record.client ? `${record.client.firstName} ${record.client.lastName}` : ''
                    )}
                />
            <TextField source="total" label="booking.price" />  
            <TextField source="totalPayed" label="booking.totalPayed" />                
            <DateField source="bookingStart" label={translate('booking.start')} locales="fr-FR" showTime/>
            <DateField source="bookingEnd" label={translate('booking.end')} locales="fr-FR" showTime/>
        </SimpleShowLayout>
    </Show>
)};