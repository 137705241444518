import {PaymentMethodList} from './paymentMethodList';
import {PaymentMethodCreate} from './paymentMethodCreate';
import {PaymentMethodEdit} from './paymentMethodEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: PaymentMethodList,
  create: PaymentMethodCreate,
  edit: PaymentMethodEdit,
};
