import * as React from 'react';
import { AutocompleteArrayInput, useRecordContext } from 'react-admin';

import { Payments } from './paymentsTable';

export const PaymentInput = (props) => {
    const { choices, input, total, payments: selectedPayments, onPaymentsChange, type, totalToPay } = props;
    const record = useRecordContext(props);

    React.useEffect(() => {
        const payments = choices.filter((payment) => input.value.includes(payment.id));
        const newPayments = payments.map((payment) => {
            const selectedPayment = selectedPayments.find((element) => element.payment.id === payment.id);
            const recordPayment = record.bookingPayments?.find((element) => element.paymentMethod.id === payment.id);
            if (selectedPayment) { //tengo data modificada en el selectedService
                return { payment, amount: selectedPayment.amount, interest: selectedPayment.interest};
            } else if (recordPayment) {
                return { payment: recordPayment.paymentMethod,  amount: recordPayment.amount, interest: recordPayment.interest}; // si no tiene en la base un valor pongo el del servicio 
             } else {
                return { payment,  amount: 0, interest: 0 };
            }
        });

        if (newPayments && selectedPayments && !arraysEqual(newPayments, selectedPayments)) {
            onPaymentsChange(newPayments);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ choices, input, onPaymentsChange, record.bookingPayments, selectedPayments]);
    
    function objectsEqual(obj1, obj2) {
       if (obj1 === null && obj2 === null) return true;

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
    
        if (keys1.length !== keys2.length) {
            return false;
        }
    
        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];
    
            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!objectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }
    
        return true;
    }

    // Helper function to compare arrays for equality
    const arraysEqual = (a, b) => {
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
       

        for (let i = 0; i < a.length; i++) {
            if (!objectsEqual(a[i], b[i])) {
                return false;
            }
        }

        return true;
    };

    return <Payments totalToPay={totalToPay} total={total} selectedPayments={selectedPayments} onPaymentsChange={onPaymentsChange} type={type} />;
};

// Render AutocompleteArrayInput separately
export const AutocompletePaymentWrapper = (props) => {
    const { total, payments, onPaymentsChange, ...rest } = props;
    
    return (
        <>
            <AutocompleteArrayInput {...rest} />
            <PaymentInput {...props} />
        </>
    );
};