import {
    Toolbar,
    SaveButton,
    DeleteButton,
  } from 'react-admin';
export const CustomToolbar = (props) => {
    
    return (
    <Toolbar {...props} >
        <SaveButton disabled={props.pristine}/>
        { props.permissions && props.permissions.includes(`DELETE_${props.entity}`) && <DeleteButton /> }
    </Toolbar>
)};