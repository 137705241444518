
import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';


const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 600,
    marginBottom: '40px'
  },
   
    
  }));

export const Services = ({ selectedServices, onServiceChange, type }) => { 
    const classes = useStyles();
    const t = useTranslate();

    if (!selectedServices || selectedServices.length === 0) {
        return <></>;
    }

    function minutesToHoursAndMinutes(totalMinutes) {
        if (typeof totalMinutes !== 'number' || totalMinutes < 0) {
          throw new Error('Input must be a non-negative number.');
        }
      
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        const hoursString = hours > 0 ? `${hours} ${hours === 1 ? t('booking.hour') : t('booking.hours')}` : '';
        const minutesString = minutes > 0 ? `${minutes} ${minutes === 1 ? t('booking.minute') : t('booking.minutes')}` : '';

        const separator = hoursString && minutesString ? ` ${t('booking.and')} ` : '';

        return `${hoursString}${separator}${minutesString}`;
    }

    const totalOfDurations = selectedServices.reduce((accumulator, currentValue) => {
        return accumulator + parseInt(currentValue.duration);
      }, 0);

    const subTotalOfPrice = selectedServices.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.price);
      }, 0);  

    function formattedCurrency(amount) {
      return parseFloat(amount).toFixed(2)
    }

    function handleChangePrice(event, item) {
      const price = event.target.value;
      
      const newServices = selectedServices.map((bookingService) => (
        bookingService.service.id === item.service.id ? { ...bookingService, price: !isNaN(price)? price : 0} : bookingService
      ));
      
      onServiceChange(newServices);
    }


    return (

      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('booking.service')}</TableCell>
            <TableCell align="right">{t('booking.price')}</TableCell>
            <TableCell align="right">{t('booking.duration')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedServices && 
            selectedServices.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
              {item.service.name}
              </TableCell>
              <TableCell align="right"> 
              {type === 'edit' ?
           <Input
            id="standard-adornment-amount"
            value={item.price}
            onChange={(event) => handleChangePrice(event, item)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          /> : formattedCurrency(item.price) }
          
          </TableCell>
              <TableCell align="right">{item.duration}{t('booking.minutes')}</TableCell>
            </TableRow>
          ))}
          <TableRow  >
            <TableCell component="th" scope="row">{t('booking.subtotal')}</TableCell> 
            <TableCell align="right">{`${formattedCurrency(subTotalOfPrice)}`}</TableCell>
            <TableCell align="right">{minutesToHoursAndMinutes(totalOfDurations)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  
        
    )
} 