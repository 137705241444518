import * as React from "react";
import { List, Datagrid, TextField, SimpleList } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const RoleList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_ROLES')) {
      return null;
    }
    

   return (
     <List {...props} title={translate('role.title')}
     actions={<ListActions showFilter={false} permissions={permissions} entity="ROLES" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="ROLES" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.name}`}
           secondaryText={(record) => record.description}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="name" label={translate('role.name')} />
           <TextField source="description" label={translate('role.description')} />
         </Datagrid>
       )}
     </List>
   );

};