import React, { useState } from 'react';
import { useTranslate } from 'ra-core';
import {
    useCreateSuggestionContext,
    useCreate, 
    useNotify
} from 'react-admin';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
} from '@material-ui/core';

export const CreateClient = () => {
    const { onCancel, onCreate } = useCreateSuggestionContext();
    const [client, setClient] = useState({});
    const [create] = useCreate('clients');
    const translate = useTranslate();
    const notify = useNotify();

    function validClient() {
        if (!client.firstName) {
            notify('Ingrese el nombre del cliente', { type: 'warning' });
            return false;
        }
        if (!client.lastName) {
            notify('Ingrese el apellido del cliente', { type: 'warning' });
            return false;
        }
        if (!client.phone) {
            notify('Ingrese el celular del cliente', { type: 'warning' })
            return false;
        }
        return true;
        //TODO: validar formato email de cliente
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (validClient()) {
            create(
                {
                    payload: {
                        data: {
                            ...client
                        },
                    },
                },
                {
                    onSuccess: ({ data }) => {
                        setClient({});
                        onCreate(data);
                    },
                }
            );
        }
    };

    return (
        <Dialog open onClose={onCancel}>
            <form onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        label={translate('user.firstName')} 
                        value={client.firstName}
                        onChange={event => setClient({...client, firstName: event.target.value})}
                        autoFocus
                        fullWidth
                    />
                    <TextField
                        label={translate('user.lastName')} 
                        value={client.lastName}
                        onChange={event => setClient({...client, lastName: event.target.value})}
                        fullWidth
                    />
                    <TextField label={translate('user.phone')} value={client.phone}
                        onChange={event => setClient({...client, phone: event.target.value})}
                        fullWidth/>
                    <TextField label={translate('user.email')} value={client.email}
                        onChange={event => setClient({...client, email: event.target.value})}
                        fullWidth/>
                </DialogContent>
                <DialogActions>
                    <Button variant="primary" type="submit">{translate('button.save')}</Button>
                    <Button onClick={onCancel}>{translate('button.cancel')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};