import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
export const ServiceCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_SERVICES')) {
      return null;
    }
    return (
      <Create {...props} title={translate('service.title')}>
        <SimpleForm redirect="list">
        <TextInput
          source="name"
          label={translate('service.name')}
          validate={required()}
        />
        <TextInput
          source="price"
          label={translate('service.price')}
          validate={required()}
        />
        <TextInput
          source="description"
          label={translate('service.description')}
          validate={required()}
        />
        <TextInput
          source="duration"
          label={translate('service.duration')}
          validate={required()}
        />
        <BooleanInput
          source="isActive"
          defaultValue={true}
          label={translate('service.isActive')}
        />
        <ReferenceArrayInput
          label="service.agents"
          source="agent_ids"
          reference="agents"
          perPage={1000}
          validate={required()}
        >
         
          <SelectArrayInput 
             allowEmpty={false}
            optionText="alias" />
        </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    );
};
