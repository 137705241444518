import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
export const RoleCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_ROLES')) {
      return null;
    }
    return (
      <Create {...props} title={translate('role.title')}>
        <SimpleForm redirect="list">
        <TextInput
          source="name"
          label={translate('role.name')}
          validate={required()}
        />
        <TextInput
          source="description"
          label={translate('role.description')}
          validate={required()}
        />
         <ReferenceArrayInput
            label="role.permissions"
            source="permissions_ids"
            reference="permissions"
            perPage={'1000'}
          >
            <SelectArrayInput
                allowEmpty={false}
                optionText="description"
              />
          </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    );
};
