import React, { useState, useCallback, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  BooleanInput,
  AutocompleteInput,
  DateField,
  useAuthProvider
} from 'react-admin';
import { AutocompleteInputWrapper } from './ServiceInput';
import { AutocompletePaymentWrapper } from './PaymentInput';
import { SelectStatus } from './SelectStatus';
import { CreateClient } from './CreateClient';
import { DateInput } from './DateInput';
import { BookingTableEdit } from './bookingTableEdit';

const CustomToolbar = (props) => {
  const { selectedServices, selectedPayments, bookingDate, record, ...rest } = props;
  const transform = useCallback((data) => {
      return {
          ...data,
          bookingServices: selectedServices,
          bookingPayments: selectedPayments,
          bookingStart: bookingDate ? bookingDate : data.bookingStart,
      };
  }, [bookingDate, selectedServices, selectedPayments]);
 

  return <Toolbar {...rest} alwaysEnableSaveButton>
      {record.currentStatus !== 'finished' && <SaveButton
          transform={transform}/> }
  </Toolbar>
};

export const BookingEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  

  const [selectedServices, setSelectedServices] = useState([]);
  const [startDate, setStartDate] = useState();
  const [total, setTotal ] = useState(0);
  const [payments, setPayments] = useState([]);
  const [totalPayment, setTotalPayment ] = useState(0);
  const filterAgent = {};
  const authProvider = useAuthProvider();
  const [filterService, setFilterService] = useState({});
  const [defaultAgentId, setDefaultAgentId] = useState();

  
  useEffect(() => {
    const agentId = authProvider.getIdentity()?.agent?.id || null;
    setDefaultAgentId(agentId); 
}, [authProvider]);


  useEffect(() => {
    if (permissions && permissions.includes('IS_ONLY_AGENT') && defaultAgentId) {
      filterAgent.id = [defaultAgentId];
    }
    setFilterService((prev) => ({ ...prev, agents: [defaultAgentId] }));
    
}, [permissions, defaultAgentId]);

  if (permissions && !permissions.includes('UPDATE_BOOKING')) {
    return null;
  }

  const handleAgentChange = (event) => {
    setDefaultAgentId(event.target.value);
  };

  const handleServicesChange = (newServices) => {
      setSelectedServices(newServices);
      const total = newServices.reduce((accumulator, currentValue) => {
        return accumulator + parseFloat(currentValue.price);
      }, 0);  
      setTotal(total);
  };

  const handlePaymentChange = (newPayments) => {
    setPayments(newPayments);
    const total = newPayments.reduce((accumulator, currentValue) => {
      return accumulator + parseFloat(currentValue.amount) + parseFloat(currentValue.interest);
    }, 0);
    setTotalPayment(total);
  };
  
  const initialValues = (data) => {
    const bookingServices = data.bookingServices || [];
    const bookingPayments = data.bookingPayments || [];
     return {
      agent_id: data.agent ? data.agent.id : null,
      client_id: data.client ? data.client.id : null,
      service_ids: bookingServices.map((bookingService) => bookingService.service.id), // indica los servicios seleccionados previamente
      payment_ids: bookingPayments.map((bookingPayment) => bookingPayment.paymentMethod.id), 
     };
   };
  
   const validateBookingEdit = (values) => {
    const errors = {};
    if (values.payed && !values.payment_ids ){
      errors.payed = 'Si esta pagado seleccione un método de pago';
    }
    if (values.payed && total > totalPayment) {
      errors.payed = 'El monto total no esta cubierto';
    }

    // si pone finalizado si o si tiene que poner pagado
   if (!values.payed && values.currentStatus === 'finished'){
    errors.payed = 'No puede finalizar si no marca pagado';
   }
    return errors
};
  return (
    <Edit {...props} title={translate('booking.title')} mutationMode="pessimistic">
      <SimpleForm 
      initialValues={initialValues} 
      warnWhenUnsavedChanges
      validate={validateBookingEdit}
      toolbar={ <CustomToolbar 
                  selectedServices={selectedServices} 
                  bookingDate={startDate}
                  selectedPayments={payments}
                />}>
      <ReferenceInput
            label={translate('booking.agent')}
            source="agent_id"
            reference="agents"
            filter={filterAgent}
            sort={{ field: 'alias', order: 'ASC' }}
            onChange={handleAgentChange}
          >
            <SelectInput optionText="alias" validate={required()} />
      </ReferenceInput>
      <ReferenceInput
            label={translate('booking.client')}
            source="client_id"
            reference="clients"
            sort={{ field: 'firstName', order: 'ASC' }}
          >
            
            <AutocompleteInput 
            optionText='fullName'  
            validate={required()} 
            create={<CreateClient />} 
            createLabel='Agregar Cliente Nuevo' 
            createItemLabel='Agregar Cliente Nuevo'/>
      </ReferenceInput>
      <SelectStatus/>
      {filterService.agents && <ReferenceArrayInput
              label="booking.service"
              source="service_ids"
              reference="services"
              filter={filterService}
              sort={{ field: 'name', order: 'ASC' }}
            >
              <AutocompleteInputWrapper type="edit" validate={required()} selectedServices={selectedServices} onServiceChange={handleServicesChange} />

        </ReferenceArrayInput> }

        <ReferenceArrayInput
              label="booking.payment"
              source="payment_ids"
              reference="paymentmethods"
        >
            <AutocompletePaymentWrapper type="edit" totalToPay={total}  total={totalPayment} payments={payments} onPaymentsChange={handlePaymentChange}/>
        </ReferenceArrayInput>   

          <BooleanInput
            source="payed"
            label={translate('booking.payed')}
          />

          <DateField source="bookingStart" label={translate('booking.start')} locales="fr-FR" showTime/>
          <DateField source="bookingEnd" label={translate('booking.end')} locales="fr-FR" showTime/>
          <p>Seleccionar para cambiar fecha y/o hora del turno </p>
          <DateInput source="bookingStart"  startDate={startDate} onDateChange={setStartDate} validate={required()}/>
          <BookingTableEdit startDate={startDate}  />
        
          <TextInput
            source="description"
            label={translate('booking.description')}
            multiline
            fullWidth
          />
      </SimpleForm>
    </Edit>
  );};
