import React from 'react';
import { useTranslate } from 'ra-core';
import { Edit, TextInput, PasswordInput, SimpleForm, required, useGetIdentity } from 'react-admin';
import {
    CircularProgress,
  } from '@material-ui/core';
const ProfileEdit = ({ staticContext, ...props }) => {
    const { identity, loading } = useGetIdentity();
    const translate = useTranslate();

    return (
        <>
        {loading ? <CircularProgress size={18} thickness={2} /> :

     <Edit mutationMode="pessimistic"
            id={identity.id}
            resource="users"
            basePath="/profile"
            title="Mis Datos"
            redirect={false} // I don't need any redirection here, there's no list page
            {...props}
        >
            <SimpleForm>
                <TextInput source="firstName" label={translate('user.firstName')} validate={required()} />
                <TextInput source="lastName" label={translate('user.lastName')} validate={required()} />
                <TextInput source="phone" label={translate('user.phone')}  validate={required()} />
                <TextInput source="email" label={translate('user.email')}  validate={required()} />
                <PasswordInput source="newPassword" type="password" label={translate('user.password')} helperText="Solo poner la contraseña si desea modificarla"/>
            </SimpleForm>
        </Edit>
    }
    </>
    );
};

export default ProfileEdit;