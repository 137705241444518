import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  DateInput,
  required
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const NoLaboraldayEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_NOLABORALDAY')) {
    return null;
  }
  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('nolaboralday.title')}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="NOLABORALDAY"/>}>
      <DateInput source="day" label="nolaboralday.day" validate={required()}/>
        <BooleanInput
          source="isHoliday"
          defaultValue={true}
          label={translate('nolaboralday.isHoliday')}
        />
      </SimpleForm>
    </Edit>
  );};
