import React from 'react';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import   { useQuery
} from 'react-admin';
import { useTranslate } from 'ra-core';
import {  format,  startOfDay} from 'date-fns';

import "react-datepicker/dist/react-datepicker.css";
registerLocale('es', es)

export const DateInput = (props) => {
    const translate = useTranslate();
    const  { startDate, onDateChange } = props;


    const { data: noLaboralDays } = useQuery({
        type: 'getList',
        resource: 'nolaboraldays', // filtra por mes anterior y mes siguiente
        payload: {pagination: { page: 1, perPage: 100}, sort:{field:'id', order: 'DESC'}, filter: {"day": format(startOfDay(new Date()), 'yyyy-MM-dd')}},
      });
     const excludeDates = noLaboralDays?.length ? noLaboralDays.map((element) => new Date(element.day)) : null;
//     const holidays = noLaboralDays?.filter((element) => element.isHoliday)?.map((element) => new Date(element.day)) || null;

  
     const { data: settings } = useQuery({
      type: 'getList',
      resource: 'settings', // TODO filtrar por tenant ?  
      payload: {pagination: { page: 1, perPage: 1}, sort:{field:'id', order: 'DESC'}, filter: {}},
    });

   
    const isOpenday = (date) => {
      const day = date.toLocaleString('en-US', { weekday: 'long' }).toLowerCase();
      const slotTime = settings.length > 0 ? settings[0].slotTimes.find((slot) => slot.day === day) : null;
      // If there is no slot defined for the day, consider it closed
      return slotTime || false;
  };
 

  function getTodayAndTwoWeeksAhead() {
    const today = new Date();
    const twoWeeksAhead = new Date();
    twoWeeksAhead.setDate(today.getDate() + 14); // Adding 14 days
    
    return {
      today,
      twoWeeksAhead
    };
  }

  const handleDateChange = (date) => {
    onDateChange(date);
  };


  const { today, twoWeeksAhead } = getTodayAndTwoWeeksAhead();
 
    return (
        <>
        <DatePicker selected={startDate} onChange={handleDateChange}
            locale="es"
            minDate={today}
            maxDate={twoWeeksAhead}
            excludeDates={excludeDates} //dias que no se atienden
            dateFormat="Pp"
            showTimeSelect
            timeIntervals={15}
            showIcon
            withPortal
            filterDate={isOpenday} // dias que esta abierto
            placeholderText={translate('booking.start')} />
            
        </>
    )

}