import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  required,
  ReferenceArrayInput,
  SelectArrayInput
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const ServiceEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_SERVICES')) {
    return null;
  }
  const initialValues = (data) => {
    const agents = data.agents || [];
    const initial =  {
      agent_ids: agents.map((agent) => agent.id),
     };
    return initial; 
   };

  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('service.title')}>
      <SimpleForm initialValues={initialValues} redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="SERVICES"/>}>
      <TextInput
          source="name"
          label={translate('service.name')}
          validate={required()}
        />
        <TextInput
          source="price"
          label={translate('service.price')}
          validate={required()}
        />
        <TextInput
          source="description"
          label={translate('service.description')}
          validate={required()}
        />
         <TextInput
          source="duration"
          label={translate('service.duration')}
          validate={required()}
        />
        <BooleanInput
          source="isActive"
          label={translate('service.isActive')}
        />
        <ReferenceArrayInput
          label="service.agents"
          source="agent_ids"
          reference="agents"
          perPage={1000}
          validate={required()}
        >
         
          <SelectArrayInput 
             allowEmpty={false}
            optionText="alias" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  );};
