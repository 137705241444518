import * as React from "react";
import {
  TopToolbar,
  CreateButton,
  ExportButton,
  FilterButton,
  sanitizeListRestProps,
  useListContext
} from 'react-admin';

export const ListActions = (props) => {
    const {
        className,
        permissions,
        entity,
        showFilter,
         ...rest
    } = props;
    const { total } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            { showFilter && <FilterButton /> }
            { permissions && permissions.includes(`ADD_${entity}`) && <CreateButton /> }
            { permissions && permissions.includes(`EXPORT_${entity}`) && <ExportButton disabled={total === 0} /> }
        </TopToolbar>
    );
  };