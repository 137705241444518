import React, { useState, useCallback, useEffect} from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  required,
  TextInput,
  ReferenceArrayInput,
  Toolbar,
  SaveButton,
  AutocompleteInput,
  useAuthProvider
} from 'react-admin';
import { AutocompleteInputWrapper } from './ServiceInput';
import { DateInput } from './DateInput';
import { CreateClient } from './CreateClient';
import { BookingTable } from './bookingTable';

const CustomToolbar = (props) => {
  
  const { selectedServices, bookingDate, ...rest } = props;

  const transform = useCallback((data) => {
      return {
          ...data,
          bookingServices: selectedServices,
          bookingStart: bookingDate
      };
  }, [bookingDate, selectedServices]);
  

  return <Toolbar {...rest} alwaysEnableSaveButton>
      <SaveButton
          transform={transform}/>
  </Toolbar>
};


export const BookingCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
   
    const [selectedServices, setSelectedServices] = useState([]);
    const [startDate, setStartDate] = useState();
    const [filterService, setFilterService] = useState({isActive: true});
    const [defaultAgentId, setDefaultAgentId] = useState();
    const authProvider = useAuthProvider();
    const filterAgent = { isActive: true };

    const handleServicesChange = (newServices) => {
      setSelectedServices(newServices);
    };

    const handleAgentChange = (event) => {
      setDefaultAgentId(event.target.value);
    };

    useEffect(() => {
      const agentId = authProvider.getIdentity()?.agent?.id || null;
      setDefaultAgentId(agentId); 
  }, [authProvider]);


    useEffect(() => {
      if (permissions && permissions.includes('IS_ONLY_AGENT') && defaultAgentId) {
        filterAgent.id = [defaultAgentId];
      }
      setFilterService((prev) => ({ ...prev, agents: [defaultAgentId] }));
      
  }, [permissions, defaultAgentId]);
      

    if (permissions && !permissions.includes('ADD_BOOKING')) {
      return null;
    }
  
    const validateBookingCreation = (values) => {
      const errors = {};
      if (!startDate) {
        errors.bookingStart = 'Seleccione una fecha y horario';
      }
      return errors
  };


    return (
      <Create {...props} title={translate('booking.title')} >
        <SimpleForm 
        redirect={'show'}
        warnWhenUnsavedChanges
        initialValues={{ agent_id: defaultAgentId }}
        validate={validateBookingCreation}
        toolbar={<CustomToolbar selectedServices={selectedServices} bookingDate={startDate} />}>
          <ReferenceInput
            label={translate('booking.agent')}
            source="agent_id"
            reference="agents"
            filter={filterAgent}
            sort={{ field: 'alias', order: 'ASC' }}
            onChange={handleAgentChange} 
          >
            <SelectInput optionText="alias" filter={{ isActive: true }} validate={required()} />

          </ReferenceInput>
          <ReferenceInput
            label={translate('booking.client')}
            source="client_id"
            reference="clients"
            filter={{ isActive: true }}
            sort={{ field: 'firstName', order: 'ASC' }}
          >
            <AutocompleteInput 
              optionText='fullName' 
              filter={{ isActive: true }} 
              validate={required()} 
              create={<CreateClient />} 
              createLabel='Agregar Cliente Nuevo' 
              createItemLabel='Agregar Cliente Nuevo'/>
            
          </ReferenceInput>
         {filterService.agents && <ReferenceArrayInput
            label="booking.service"
            source="service_ids"
            reference="services"
            filter={filterService}
            sort={{ field: 'name', order: 'ASC' }}
          >
            <AutocompleteInputWrapper type="create" validate={required()} selectedServices={selectedServices} onServiceChange={handleServicesChange} />

          </ReferenceArrayInput> }
           
          <DateInput source="bookingStart"  startDate={startDate} onDateChange={setStartDate} validate={required()}/>
          { startDate && <BookingTable bookingDate={startDate}   /> }
           
          <TextInput
            source="description"
            label={translate('booking.description')}
            multiline
            fullWidth
          />

        </SimpleForm>
      </Create>
    );
};
