import * as React from 'react';
import { useTranslate } from 'ra-core';
import { Create, SimpleForm, email,  required, TextInput } from 'react-admin';
export const ClientCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_CLIENTS')) {
      return null;
    }
    return (
      <Create {...props} mutationMode="pessimistic" title={translate('client.title')}>
        <SimpleForm redirect="list">
          <TextInput source="firstName" label={translate('user.firstName')} validate={required()}/>
          <TextInput source="lastName" label={translate('user.lastName')} validate={required()}/>
          <TextInput source="phone" label={translate('user.phone')} validate={required()}/>
          <TextInput source="email" label={translate('user.email')} validate={email()}/>
        </SimpleForm>
      </Create>
    );
};
