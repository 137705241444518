import {UsersList} from './usersList';
import {UserCreate} from './userCreate';
import {UserEdit} from './userEdit';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list: UsersList,
  create: UserCreate,
  edit: UserEdit
};
