import {TextField} from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useState } from 'react';

const InputPassword = ({
  //eslint-disable-line react/prop-types
  meta: { touched, error },
  //eslint-disable-line react/prop-types
  input: inputProps,
  ...props
}) => {
  const [toggle_password, setTooglePassword]= useState(false);
  
  function togglePasswordHide(){
    setTooglePassword(!toggle_password);
  };


  return (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    InputProps = {{
      ...inputProps,
      
        endAdornment: ( 
        <InputAdornment position="end"> {
            toggle_password ? ( 
            <VisibilityIcon className = "cursor_pointer"
              onClick = {
                togglePasswordHide
              }
              />
            ) : ( 
            <VisibilityOffIcon onClick = {
                togglePasswordHide
              }
              />
            )
          } 
          </InputAdornment>
        ),
      
    }}
    
    {...props}
    fullWidth
    type = { toggle_password ? "text" : "password" }
  />
)};

export default InputPassword;
