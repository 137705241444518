import simpleRestProvider from 'ra-data-simple-rest';
import config from '../config';
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = JSON.parse(localStorage.getItem('auth'));
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('application', `Panel`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(config.API_URL, httpClient);
const myDataProvider = {
  ...dataProvider,
  create: (resource, params) => {
    return dataProvider.create(resource, params);
  },
  update: (resource, params) => {
    return dataProvider.update(resource, params);
  },
  getReport: (resource, params) => {
    const url = `${config.API_URL}/${resource}?${stringify(params)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
  cancelAccount:(resource, params) => {
    const url = `${config.API_URL}/${resource}?${stringify(params)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },
};

export default myDataProvider;
