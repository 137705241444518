import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  required
} from 'react-admin';
export const PaymentMethodCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_METHOD')) {
      return null;
    }
    return (
      <Create {...props} title={translate('paymentMethod.title')}>
        <SimpleForm redirect="list">
        <TextInput
          source="name"
          label={translate('paymentMethod.name')}
          validate={required()}
        />
        <TextInput
          source="key"
          label={translate('paymentMethod.key')}
          validate={required()}
        />
        <TextInput
          source="percentage"
          label={translate('paymentMethod.percentage')}
        />
        <BooleanInput
          source="isActive"
          defaultValue={true}
          label={translate('paymentMethod.isActive')}
        />
        </SimpleForm>
      </Create>
    );
};
