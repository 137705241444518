import * as React from "react";
import { List, Datagrid, TextField, BooleanField, SimpleList, FunctionField, ReferenceArrayInput, SelectArrayInput } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const ServiceList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_SERVICES')) {
      return null;
    }

    const serviceFilters = [
    <ReferenceArrayInput
        label="service.agents"
        source="agents"
        reference="agents"
      >
        <SelectArrayInput
          source="agents"
          allowEmpty={true}
          optionText="alias"
        />
    </ReferenceArrayInput>,
    ];

    function minutesToHoursAndMinutes(duration) {
      const totalMinutes = parseInt(duration);
      if (typeof totalMinutes !== 'number' || totalMinutes < 0) {
        throw new Error('Input must be a non-negative number.');
      }
    
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
  
      const hoursString = hours > 0 ? `${hours} ${hours === 1 ? translate('booking.hour') : translate('booking.hours')}` : '';
      const minutesString = minutes > 0 ? `${minutes} ${minutes === 1 ? translate('booking.minute') : translate('booking.minutes')}` : '';
  
      const separator = hoursString && minutesString ? ` ${translate('booking.and')} ` : '';
  
      return `${hoursString}${separator}${minutesString}`;
  }

   return (
     <List title={translate('service.title')}
     filters={serviceFilters}
     actions={<ListActions showFilter={true} permissions={permissions} entity="SERVICES" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="SERVICES" {...props}/>}
     {...props}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.name}`}
           secondaryText={(record) => record.price}
           tertiaryText={(record) => minutesToHoursAndMinutes(record.duration)}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <TextField source="name" label={translate('service.name')} />
           <TextField source="price" label={translate('service.price')} />
           <FunctionField label={translate('service.duration')} render={ record => ( record.duration ? minutesToHoursAndMinutes(record.duration) : '' )} />
           <BooleanField
             source="isActive"
             label={translate('service.isActive')}
             sortable={false}
           />
         </Datagrid>
       )}
     </List>
   );

};