import * as React from "react";
import { List, Datagrid, TextField, SimpleList, DateField, FunctionField, DateInput, ReferenceInput, SelectInput, useAuthProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ChipStatus } from './ChipStatus';
import {  format, isBefore, startOfToday } from 'date-fns';
import { translatedStatus } from './type';
import { ListActions } from '../shared/ListActions';

export const BookingList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const authProvider = useAuthProvider();
    let filter = null;
    if (permissions && !permissions.includes('VIEW_BOOKING')) {
      
      return null;
    }
    const today = format(new Date(), 'yyyy-MM-dd');
    const bookingFilters = [
      <DateInput
      label={translate('booking.date')}
      source="bookingStart"
      defaultValue={today}
    />
    ];
 
    if (permissions && !permissions.includes('IS_ONLY_AGENT')) {
      bookingFilters.push(<ReferenceInput
        label="booking.agent"
        source="agent"
        reference="agents"
      >
        <SelectInput
          source="agent"
          allowEmpty={true}
          optionText="alias"
        />
    </ReferenceInput>);
    } else {
      filter = {'agent': authProvider.getIdentity()?.agent?.id || null};
    }
  //TODO moverlo a un utils pero no me funca con translate
    function minutesToHoursAndMinutes(duration) {
      const totalMinutes = parseInt(duration);
      if (typeof totalMinutes !== 'number' || totalMinutes < 0) {
        throw new Error('Input must be a non-negative number.');
      }
    
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
  
      const hoursString = hours > 0 ? `${hours} ${hours === 1 ? translate('booking.hour') : translate('booking.hours')}` : '';
      const minutesString = minutes > 0 ? `${minutes} ${minutes === 1 ? translate('booking.minute') : translate('booking.minutes')}` : '';
  
      const separator = hoursString && minutesString ? ` ${translate('booking.and')} ` : '';
  
      return `${hoursString}${separator}${minutesString}`;
  }

  function translateCurrentStatus(status){
    const current = translatedStatus[status];
    return translate(current.label);
  }
  function showHour(data){
    return format(new Date(data), 'dd-MM-yyyy H:mm');
  }

  function isBeforeToday(data){
    return isBefore(new Date(data), startOfToday())
  }

  function isNotClosed(status) {
    return status !== 'finished' && status !== 'canceled';
  }

  const postRowStyle = (record, index) => ({
    backgroundColor: isBeforeToday(record.bookingStart) && isNotClosed(record.currentStatus) ? 'red' : 'white',
  });

  function showServices(record) {
    return record.bookingServices.map((bservice)=> bservice.service.name).join(" - ");
  }
  const bookingRowClick = (id, basePath, record) => /*record.currentStatus !== 'finished'  ? */'edit' /*: 'show'*/;

   return (
     <List {...props} title={translate('booking.title')} bulkActionButtons={false} filters={bookingFilters} 
     sort={{ field: 'bookingStart', order: 'DESC' }}
     filter={filter}
     actions={<ListActions showFilter={true} permissions={permissions} entity="BOOKING" {...props}/>}
     >
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.client.firstName} ${record.client.lastName}`}
           secondaryText={(record) => `${showHour(record.bookingStart)} ${showServices(record)}`}
           tertiaryText={(record) => translateCurrentStatus(record.currentStatus)}
           rowStyle={postRowStyle}
         />
       ) : (
         <Datagrid rowClick={bookingRowClick} rowStyle={postRowStyle}>
           <TextField source="id" />
            <FunctionField
                    label='booking.agent'
                    render={record => (
                        record.agent ? `${record.agent.alias}` : ''
                    )}
                />
           <FunctionField
                    label='booking.client'
                    render={record => (
                        record.client ? `${record.client.firstName} ${record.client.lastName}` : ''
                    )}
                />
           <FunctionField
                    label='booking.service'
                    render={record => (
                        record.bookingServices ? showServices(record) : ''
                    )}
                />     
           <ChipStatus source="currentStatus" label={translate('booking.currentstatus')} />
           <DateField source="bookingStart" label={translate('booking.date')} locales="fr-FR" showTime/>
           <DateField source="bookingEnd" label={translate('booking.end')} locales="fr-FR" showTime/>
           <FunctionField label={translate('booking.duration')} render={ record => ( record.estimatedDuration ? minutesToHoursAndMinutes(record.estimatedDuration) : '' )} />
         </Datagrid>
       )}
     </List>
   );

};