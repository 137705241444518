import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  BooleanInput,
  TextInput,
  required, email
} from 'react-admin';
export const AgentCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_AGENTS')) {
      return null;
    }
    return (
      <Create {...props} title={translate('agent.title')} >
        <SimpleForm redirect="list">
        <TextInput
          source="alias"
          label={translate('agent.alias')}
          validate={required()}
        />
        <TextInput
          source="firstName"
          label={translate('agent.firstName')}
          validate={required()}
        />
        <TextInput
          source="lastName"
          label={translate('agent.lastName')}
          validate={required()}
        />
        <TextInput
          source="phone"
          label={translate('agent.phone')}
          validate={required()}
        />
        <TextInput
          source="email"
          label={translate('agent.email')}
          validate={email()}
        />
        <TextInput
          source="description"
          label={translate('agent.description')}
        />
        <BooleanInput
          source="isActive"
          defaultValue={true}
          label={translate('agent.isActive')}
        />
        </SimpleForm>
      </Create>
    );
};
