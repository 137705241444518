import * as React from "react";
import { List, Datagrid, TextField, SimpleList } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const SettingList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_SETTING')) {
      return null;
    }
   return (
     <List {...props} title={translate('setting.title')}
     actions={<ListActions showFilter={false} permissions={permissions} entity="SETTING" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="SETTING" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.name}`}
           secondaryText={(record) => record.email}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <TextField source="name" label={translate('setting.name')} />
           <TextField source="email" label={translate('setting.email')} />
         </Datagrid>
       )}
     </List>
   );

};