import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  email, required
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const ClientEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_CLIENTS')) {
    return null;
  }
  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('client.title')}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="CLIENTS" {...props}/>}>
        <TextInput source="id" />
        <TextInput source="firstName" label={translate('user.firstName')} validate={required()}/>
        <TextInput source="lastName" label={translate('user.lastName')} validate={required()}/>
        <TextInput source="phone" label={translate('user.phone')} validate={required()}/>
        <TextInput source="email" label={translate('user.email')} validate={email()}/>
        <BooleanInput source="isActive" label={translate('user.isActive')} />
      </SimpleForm>
    </Edit>
  );};
