import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextInput,
  required
} from 'react-admin';
import { CustomToolbar } from '../shared/CustomToolBar';

export const PaymentMethodEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  if (permissions && !permissions.includes('UPDATE_METHOD')) {
    return null;
  }
  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('paymentMethod.title')}>
      <SimpleForm warnWhenUnsavedChanges
      redirect="list" toolbar={<CustomToolbar permissions={permissions} entity="METHOD"/>}>
      <TextInput
          source="name"
          label={translate('paymentMethod.name')}
          validate={required()}
        />
        <TextInput
          source="key"
          label={translate('paymentMethod.key')}
          validate={required()}
        />
        <TextInput
          source="percentage"
          label={translate('paymentMethod.percentage')}
        />
        <BooleanInput
          source="isActive"
          label={translate('paymentMethod.isActive')}
        />
      </SimpleForm>
    </Edit>
  );};
