import englishMessages from 'ra-language-english';

const customEnglishMessages = {
  ...englishMessages,
  sidebar: {
    dashboard: 'Dashboard',
    users: 'Users',
    agents: 'Agents',
    services: 'Servicies',
    paymentmethods: 'Payment Methods',
    clients: 'Clients',
    bookings: 'Bookings',
    nolaboralday: 'No labor days',
    settings: 'Settings',
    account: 'Account',
    report: 'Report',
    roles: 'Roles',
  },
  role: {
    name: 'Name',
    description: 'Description',
    permissions: 'Permissions',
    title: 'Rols',
  },
  report:{
    title_income: 'Report',
    from: 'from',
    to: 'to',
    title_balance: 'Last Balance',
    balance: 'Total',
    total_income: 'Income Total',
    total_outcome: 'Outcome Total',
    total_interest: 'Total Interest',
    interest: 'Interest',
    difference: 'Balance',
    title_fixed: 'Fixes',
    title_credit: 'Credits',
    title_cashout: 'Cash out',
    title_income_list: 'List of Incomes',
    title_outcome_list: 'List of Outcomes without category',
    date: 'Date',
    amount: 'Amount',
    description: 'Description',
    user: 'User',
    total: 'Total',
    month: 'Month',
    choose_month: 'Choose a Month',
    booking: 'Booking',
    method:"Method"
  },
  login: {
    link_forgot_password: 'Forgot Password?',
    email_required: 'Email Required',
    password_required: 'Password Required',
    password: 'Password',
    confirm_password: 'Confirm Password',
    confirm_password_required: 'Confirm Password Required',
    btn: {
      send: 'Send',
    },
  },
  home:{
    title: 'Welcome to the Admin',
    access: 'Access',
  },
  forgot: {
    email: 'Email',
  },
  user: {
    firstName: 'Name',
    lastName: 'Lastname',
    email: 'Email',
    isActive: 'Active',
    createdAt: 'Created',
    updatedAt: 'Updated',
    password: 'Password',
    confirm_password: 'Confirm Password',
    roles: 'Role',
    phone: 'Phone',
    title: 'User',
    agent: 'Agent'
  },
  button:{
    save: 'Save',
    cancel: 'Cancel',
  },
  agent: {
    firstName: 'Name',
    lastName: 'Lastname',
    email: 'Email',
    isActive: 'Active',
    createdAt: 'Created',
    updatedAt: 'Updated',
    phone: 'Phone',
    description: 'Description',
    title: 'Agent',
    alias: 'NickName',
  },
  service: {
    name: 'Name',
    price: 'Precio',
    isActive: 'Active',
    createdAt: 'Created',
    updatedAt: 'Updated',
    description: 'Description',
    title: 'Servicios',
    duration: 'Duration',
    agents: 'Agents',
  },
  paymentMethod: {
    name: 'Name',
    isActive: 'Active',
    createdAt: 'Created',
    updatedAt: 'Updated',
    key: 'Key',
    title: 'Payment Method',
    percentage: 'Percentage',
    interest: 'Interest',
    amount: 'Amount'
  },
  appBar: {
    english: 'English',
    spanish: 'Spanish',
  },
  booking: {
    title: 'Booking',
    client: 'Client',
    agent: 'Agent',
    description: 'Description',
    duration: 'Duration',
    price: 'Price',
    start: 'Booking Date',
    end: 'Booking End',
    services: 'Services',
    service: 'Service',
    services_not_selected: 'Services not selected',
    hours: 'Hours',
    total: 'Total',
    minutes: 'Minutes',
    minute: 'Minute',
    hour: 'Hour',
    and: 'and',
    date: 'Fecha',
    payed: 'Payed',
    payment: 'Payment',
    currentstatus: 'Status',
    discount: 'Discount',
    subtotal: 'Subtotal',
    status: {
      pending: 'Pending',
      waiting: 'Waiting',
      confirmed: 'Confirmed',
      canceled: 'Canceled',
      finished: 'Finished',
    },
    totalPayed: 'Total Payed',
  },
  nolaboralday:{
    title: 'No labor day',
    day: 'Day',
    isHoliday: 'Holiday'
  },
  setting:{
    title: 'Settings',
    name: 'Name',
    email: 'Email'
  },
  account: {
    title: 'Movements',
    amount: 'Amount',
    interest: 'Interest',
    balance: 'Balance',
    type: 'Type',
    description: 'Description',
    income:'income',
    outcome: 'outcome',
    user: 'user',
    date: 'date',
    payment: 'Payment Method',
    fixed: 'Fixed',
    credit: 'Credit',
    cashout: 'Cash Out',
    category: 'Category',
    impactedAt: 'Impacted At',
    impactedAtHelper: 'Date to impacte on the report',
    from: 'From',
    to: 'To',
    canceled: 'Canceled'
  },
  client:{
    title: 'Client'
  }
  
};
export default customEnglishMessages;