import React, { useState} from "react";
import { List, Datagrid, TextField, SimpleList, DateField, FunctionField, DateInput, 
  SelectInput, ReferenceArrayInput, SelectArrayInput, linkToRecord, SimpleShowLayout, Confirm, useNotify, useRefresh} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import {  format } from 'date-fns';
import { Link } from 'react-router-dom';
import { ListActions } from '../shared/ListActions';
import Button from '@material-ui/core/Button';
import myDataProvider from '../../dataProvider';
import CheckIcon from '@material-ui/icons/Close';

const BookingShow = (props) => {
  const translate = useTranslate();
  const { record } = props;
  const showBooking = linkToRecord('/bookings', record.booking.id, 'show');
  return (
     <div>
      <Link to={showBooking}>Ver Turno</Link>
      <SimpleShowLayout>
        <TextField source="booking.totalPayed" label="booking.totalPayed" />    
        <DateField source="booking.bookingStart" label={translate('booking.start')} locales="fr-FR" showTime/>
        <DateField source="booking.bookingEnd" label={translate('booking.end')} locales="fr-FR" showTime/>
      </SimpleShowLayout>
     </div>

)};


const BookingCancel = (props) => {

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

   function handleConfirm() {
    setLoading(true);
    const { record } = props;
    myDataProvider.getReport(`account/cancel`,{id: record.id})
        .then((response) => {
          notify('Movimiento cancelado', { type: 'success' })
          refresh();
        })
        .catch((e) => {
            notify('Error: loading data', { type: 'warning' })
        })
        .finally(() => {
            setLoading(false);
            setOpen(false);
        });
  }

  function showConfirm(event) {
    event.stopPropagation();
    setOpen(true);
  }

  function handleDialogClose() {
    setOpen(false);
    setLoading(false);
  }

  return (
    <>
    { props.record && !props.record.canceled && <Button variant="contained" color="secondary" size="small" type="button" onClick={(event) => showConfirm(event) }>Cancelar</Button> }
    <Confirm
                isOpen={open}
                loading={loading}
                title="Cancelar"
                content="Seguro quiere cancelar este movimiento? Esto altera el saldo"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            </>
  )


}


export const AccountList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const today = format(new Date(), 'yyyy-MM-dd');
    const typeChoice = [
      {
        id: 'income',
        name: 'account.income',
      },
      {
        id: 'outcome',
        name: 'account.outcome',
      },
    ];

    if (permissions && !permissions.includes('VIEW_ACCOUNT')) {
      return null;
    }
    const accountFilters = [
      <DateInput
      label={translate('account.from')}
      source="from"
      defaultValue={today}
      />,
      <DateInput
      label={translate('account.to')}
      source="to"
      defaultValue={today}
      />,
    <SelectInput
        label={translate('account.type')}
        source="type"
        choices={typeChoice}
        optionText="name"
      />,
    <ReferenceArrayInput
        label="account.payment"
        source="paymentMethod"
        reference="paymentmethods"
      >
        <SelectArrayInput
          source="paymentMethod"
          allowEmpty={true}
          optionText="name"
        />
    </ReferenceArrayInput>
    ];

    const translateType = {
      'income': translate('account.income'),
      'outcome': translate('account.outcome')
    };

    const showAmount = (amount, type) => {
      return type === 'income' ? amount : `-${amount}`;
    }

    const accountRowStyle = (record, index) => ({
      backgroundColor: record.type === 'outcome' ? '#ffeeee' : 'white',
    });

    function showResumeData(type, amount) {
      return `${translateType[type]} $${amount}`;
    }

    function showHour(data){
      return format(new Date(data), 'dd-MM-yyyy H:mm');
    }
   return (
     <List {...props} bulkActionButtons={false} title={translate('account.title')} 
     actions={<ListActions showFilter={true} permissions={permissions} entity="ACCOUNT" {...props}/>}
            sort={{ field: 'createdAt', order: 'DESC' }}
            filters={accountFilters}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => showResumeData(record.type, record.amount)}
           secondaryText={(record) => showHour(record.createdAt)} 
           tertiaryText={(record) => `Saldo: $${record.balance}`}
           linkType={"show"}
         />
       ) : (
         <Datagrid rowClick="show" rowStyle={accountRowStyle} expand={<BookingShow />} isRowExpandable={row => row.booking}>

           <FunctionField
                    label='account.type'
                    render={record => (
                        record.type ? `${translateType[record.type]}`: ''
                    )}
                />
            <FunctionField
                label='account.amount'
                render={record => (
                    record.amount ? `${showAmount(record.amount, record.type)}`: ''
                )}
            />
           <TextField source="interest" label={translate('account.interest')} />
           <TextField source="createdBy" label={translate('account.user')} />
           <FunctionField
                label='account.payment'
                render={record => (
                    record.paymentMethod ? `${record.paymentMethod.name}`: ''
                )}
            />
           <DateField source="createdAt" label={translate('account.date')} locales="fr-FR" showTime/>
           <DateField source="impactedAt" label={translate('account.impactedAt')} locales="fr-FR"/>
           <TextField source="balance" label={translate('account.balance')} />
           <FunctionField
             label={translate('account.canceled')}
             render={record => (
              record.canceled ? <CheckIcon /> : ''
          )}
           />
           <BookingCancel />
         </Datagrid>
       )}
     </List>
     
   );

};