import * as React from "react";
import { List, Datagrid, TextField, BooleanField, SimpleList } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const PaymentMethodList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_METHOD')) {
      return null;
    }
   return (
     <List {...props} title={translate('paymentMethod.title')}
     actions={<ListActions showFilter={false} permissions={permissions} entity="METHOD" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="METHOD" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.name}`}
           secondaryText={(record) => record.isActive}
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="name" label={translate('paymentMethod.name')} />
           <TextField source="percentage" label={translate('paymentMethod.percentage')} />
           <BooleanField
             source="isActive"
             label={translate('paymentMethod.isActive')}
             sortable={false}
           />
         </Datagrid>
       )}
     </List>
   );

};