import * as React from 'react';
import { useTranslate } from 'ra-core';
import { 
  Create, 
  SimpleForm, 
  PasswordInput,
  TextInput,
  ReferenceArrayInput,
  SelectArrayInput,
  required,
  ReferenceInput,
  SelectInput } from 'react-admin';
export const UserCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_USERS')) {
      return null;
    }
    const initialValues = (data) => {
      const roles = data.roles || [];
      const initial =  {
        role_ids: roles.map((role) => role.id),
       };
      return initial; 
     };
    return (
      <Create {...props} mutationMode="pessimistic" title={translate('user.title')}>
        <SimpleForm initialValues={initialValues} redirect="list">
          <TextInput source="firstName" label={translate('user.firstName')} validate={required()}/>
          <TextInput source="lastName" label={translate('user.lastName')} validate={required()}/>
          <TextInput source="email" label={translate('user.email')} validate={required()}/>
          <PasswordInput
            source="password"
            label={translate('user.password')}
            validate={required()}
          />
          <PasswordInput
            source="confirm_password"
            label={translate('user.confirm_password')}
            validate={required()}
          />
          <ReferenceArrayInput
          label="user.roles"
          source="role_ids"
          reference="roles"
          perPage={1000}
          validate={required()}
        >
          
          <SelectArrayInput 
              allowEmpty={false}
              optionText="name" />
        </ReferenceArrayInput>
        <ReferenceInput
          label={translate('user.agent')}
          source="agent_id"
          reference="agents"
          filter={{ isActive: true  }}
         >
          <SelectInput optionText="alias"  />
        </ReferenceInput>
        </SimpleForm>
      </Create>
    );
};
