import * as React from 'react';
import { useTranslate } from 'ra-core';
import {
  Create,
  SimpleForm,
  BooleanInput,
  DateInput,
  required
} from 'react-admin';
export const NoLaboraldayCreate = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('ADD_NOLABORALDAY')) {
      return null;
    }
    return (
      <Create {...props} title={translate('nolaboralday.title')}>
        <SimpleForm redirect="list">
        <DateInput source="day" label="nolaboralday.day" validate={required()}/>
        <BooleanInput
          source="isHoliday"
          defaultValue={true}
          label={translate('nolaboralday.isHoliday')}
        />
        </SimpleForm>
      </Create>
    );
};
