import React, { useState, useCallback } from 'react';
import { useTranslate } from 'ra-core';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  Toolbar,
  SaveButton
} from 'react-admin';
import  AddTimeSlotsForm from './slotTime';


const CustomToolbar = (props) => {
  const transform = useCallback((data) => {
      return {
          ...data,
          slotTimes: props.timeSlots,
      };
  }, [props.timeSlots]);
 const { timeSlots, ...rest} = props;

  return <Toolbar {...rest} alwaysEnableSaveButton>
      <SaveButton
          transform={transform}/>
  </Toolbar>
};

export const SettingEdit = ({ permissions, ...props }) => {
  const translate = useTranslate();
  const [timeSlots, setTimeSlots] = useState([]);
  if (permissions && !permissions.includes('UPDATE_SETTING')) {
    return null;
  }
  const handleTimeSlotsChange = (newTimeSlots) => {
    setTimeSlots(newTimeSlots);
  };


  return (
    <Edit {...props} mutationMode="pessimistic" title={translate('setting.title')}>
      <SimpleForm  toolbar={<CustomToolbar timeSlots={timeSlots} />} >
        <TextInput
          source="name"
          label={translate('setting.name')}
          validate={required()}
        />
        
        <TextInput
          source="email"
          label={translate('setting.email')}
          validate={required()}
        />
        <AddTimeSlotsForm timeSlots={timeSlots} onTimeSlotsChange={handleTimeSlotsChange} />
      </SimpleForm>
    </Edit>
  );};
