import React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import {
  Edit,
  SimpleForm,
  RadioButtonGroupInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  DateInput,
  useRecordContext
} from 'react-admin';

const CustomToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
     
  </Toolbar>
);

const useStyles = makeStyles((theme) => ({
  
  redBackground: {
    backgroundColor: '#ffeeee',
    display: 'inline-grid'
  },
  noredBackground: {
    display: 'inline-grid'
  },
}));

const CustomEditForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const record = useRecordContext();

  
  const isOutcome = record.type === 'outcome';

   return (
    <div className={isOutcome ? classes.redBackground : classes.noredBackground}  >
      
      <RadioButtonGroupInput source="type"  
      label={translate('account.type')}  
      disabled
      choices={[
                { id: 'income', name: 'account.income' },
                { id: 'outcome', name: 'account.outcome' },

      ]} 
      />
      <TextInput
        source="amount"
        label={translate('account.amount')}
        disabled
      />
     
      <TextInput
        source="description"
        label={translate('account.description')}
      />

      <ReferenceInput
          label={translate('account.payment')}
          source="paymentMethod_id"
          reference="paymentmethods"
          disabled
        >
          <SelectInput optionText="name"  />
      </ReferenceInput>
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return formData.type === 'outcome' ? (
              <SelectInput
                source="category"
                choices={[
                  { id: 'cashout', name: 'account.cashout' },
                  { id: 'fixed', name: 'account.fixed' },
                  { id: 'credit', name: 'account.credit' },
                ]}
                {...rest}
              />
            ) : null;
          }}
        </FormDataConsumer>
        <DateInput label={translate('account.impactedAt')} source="impactedAt" helperText={translate('account.impactedAtHelper')}/>
      </div>
    
  );
}

export const AccountEdit = ({ permissions, ...props }) => {
    const translate = useTranslate();

    if (permissions && !permissions.includes('UPDATE_ACCOUNT')) {
      return null;
    }
   
    const initialValues = (data) => {
      return {
       type: data.type || 'income',
       amount: data.amount || 0,
       description: data.description || '',
       paymentMethod_id: data.paymentMethod?.id || null,
       category: data.category || null
      };
    };
    
     return (
      <Edit  {...props} title={translate('account.title')} mutationMode="pessimistic">
        <SimpleForm  toolbar={<CustomToolbar />} redirect="list" initialValues={initialValues} >
          <CustomEditForm />
        </SimpleForm>
      </Edit>
    );
};
