import * as React from 'react';
import { Show, SimpleShowLayout, TextField, DateField, FunctionField } from 'react-admin';
import { useRecordContext } from 'react-admin';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  
    redBackground: {
      backgroundColor: '#ffeeee',
    },
  }));

  const CustomShowLayout = (props) => {
    const classes = useStyles();
    const record = useRecordContext();
    // Determine if the record type is 'income'
    const isOutcome = record && record.type === 'outcome';
    const translate = useTranslate();
    const translateType = {
        'income': translate('account.income'),
        'outcome': translate('account.outcome')
      };

      
  
    const showAmount = (amount, type) => {
        return type === 'income' ? amount : `-${amount}`;
    }
  
    return (
        <SimpleShowLayout className={isOutcome ? classes.redBackground : ''}>
        <FunctionField
                    label='account.type'
                    render={record => (
                        record.type ? `${translateType[record.type]}`: ''
                    )}
                />
            <FunctionField
                label='account.amount'
                render={record => (
                    record.amount ? `${showAmount(record.amount, record.type)}`: ''
                )}
            />
            <TextField source="interest" label="account.interest" />
            <DateField source="createdAt" label={translate('account.date')} locales="fr-FR" showTime/>
            <TextField source="description" label="account.description" />
            <TextField source="createdBy" label="account.user" />
            <FunctionField
                label='account.category'
                render={record => (
                    record.category ? `${translate(`account.${record.category}`)}`: ''
                )}
            /> 
            <DateField source="impactedAt" label={translate('account.impactedAt')} locales="fr-FR"/>
        </SimpleShowLayout>
    );
  };

export const AccountShow = ({ permissions, ...props }) => {
    const translate = useTranslate();
    if (permissions && !permissions.includes('VIEW_ACCOUNT')) {
        return null;
    }

    return (
    <Show  {...props}  title={translate('account.title')}>
        <CustomShowLayout {...props} />
    </Show>
)};