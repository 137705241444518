
import * as React from 'react';
import { useTranslate } from 'ra-core';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: 600,
    marginBottom: '40px'
  },
   
    
  }));

export const Payments = ({ total, selectedPayments, onPaymentsChange,type ,totalToPay }) => { 
    const classes = useStyles();
    const t = useTranslate();

    if (!selectedPayments || selectedPayments.length === 0) {
        return <></>;
    }

    function formattedCurrency(amount) {
      return parseFloat(amount).toFixed(2)
    }

    function calculateInterest(percentage, amount) {
      if (!percentage || !amount) {
        return 0;
      }
      percentage = parseFloat(percentage);
      amount = parseFloat(amount)
      // Ensure that both percentage and amount are positive numbers
      if (typeof percentage !== 'number' || typeof amount !== 'number' || percentage < 0 || amount < 0) {
        return 0; // You can choose to return an error code, throw an exception, or handle it differently based on your requirements.
      }

      // Calculate interest by multiplying the percentage with the amount
      const interest = (percentage / 100) * amount;

      // Return the calculated interest
      return interest;
    }

    function handleChangeAmount(event, item){
      const amount = event.target.value;
      calculatePayment(item.payment.id, amount);
    }

    function chargeTotalAmount(event, item) {
      calculatePayment(item.payment.id, totalToPay);
    }

    function calculatePayment(id, amount) {
      const newPayments = selectedPayments.map((payment) => (
        payment.payment.id === id ? { ...payment, amount: !isNaN(amount)? amount : 0, interest: calculateInterest(payment.payment.percentage, amount)} : payment
      ));
      onPaymentsChange(newPayments);
    }


    return (

      <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('paymentMethod.title')}</TableCell>
            <TableCell align="right">{t('paymentMethod.amount')}</TableCell>
            <TableCell>{t('paymentMethod.percentage')}</TableCell>
            <TableCell align="right">{t('paymentMethod.interest')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedPayments && 
            selectedPayments.map((item, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
              {item.payment.name}
              </TableCell>
              <TableCell align="right"> 
              {type === 'edit' ?
           <Input
            id="standard-adornment-amount"
            value={item.amount}
            onChange={(event) => handleChangeAmount(event, item)}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
          /> : formattedCurrency(item.amount) }
          
          </TableCell>
            <TableCell align="right">{item.payment.percentage ?? 0}%</TableCell>
            <TableCell align="right">{formattedCurrency(item.interest)}</TableCell>
            <TableCell>
             { index === 0 && <Button variant="contained" color="primary" onClick={(event)=> chargeTotalAmount(event, item)}>Paga Total </Button> }
            </TableCell>
            </TableRow>
          ))}
        <TableRow>
          <TableCell  colSpan={3}> Total</TableCell>
          <TableCell align="right">${total}</TableCell>
          <TableCell></TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  
        
    )
} 