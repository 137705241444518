
import React, { useState, useEffect } from 'react';
import { useTranslate } from 'ra-core';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { useRecordContext } from 'react-admin';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    addButton: {
        marginTop: '20px'
    },
    to: {
        marginLeft: '20px'
    },
    table: {
        maxWidth: 400,
      },
    
}));

const AddTimeSlotsForm = (props ) => {
  const { timeSlots, onTimeSlotsChange } = props;
  const classes = useStyles();
  const t = useTranslate();
  const [day, setDay] = useState('monday');
  const [from, setFrom] = useState('09:00');
  const [to, setTo] = useState('13:00');

  const handleAddSlot = () => {
    if (day && from && to) {
        const newTimeSlots = [...timeSlots, { day, from, to }];  
        onTimeSlotsChange(newTimeSlots);
        setDay('monday');
        setFrom('09:00');
        setTo('13:00');
    }
  };

    const days = [
        { value: 'monday',label: t('day.monday')} ,
        { value: 'tuesday', label: t('day.tuesday')},
        { value: 'wednesday', label: t('day.wednesday')},
        { value: 'thursday', label: t('day.thursday')},
        { value: 'friday', label: t('day.friday')},
        { value: 'saturday', label: t('day.saturday')},
        { value: 'sunday', label: t('day.sunday')}
    ];
    const record = useRecordContext(props);
    useEffect(() => {
        if (timeSlots.length === 0 && record && record.slotTimes) {
          onTimeSlotsChange(record.slotTimes);
        }
      }, [record, timeSlots, onTimeSlotsChange]);

    const handleRemoveSlot = (index) => {
        const newTimeSlots = [...timeSlots];
        newTimeSlots.splice(index, 1);
        onTimeSlotsChange(newTimeSlots);
    };
  return (
    <div>
      <h2>{t('slot.add_slot')}</h2>
      <div>
         
          <Select
          labelId="slot-simple-select-label"
          id="slot-simple-select"
          label={t('slot.day')}
          value={day}
          onChange={(e) => setDay(e.target.value)}
        >
          {days.map((d) => (
             <MenuItem key={d.value} value={d.value}>{d.label}</MenuItem>
          ))}
        </Select>

      </div>
      <div className={classes.addButton}>


          <TextField
        id="time"
        label={t('slot.from')}
        type="time"
        value={from}
        onChange={(e) => setFrom(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
       
          <TextField
          className={classes.to}
        id="time"
        label={t('slot.to')}
        type="time"
        value={to}
        onChange={(e) => setTo(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 300, // 5 min
        }}
      />
    
      </div>
      <Button className={classes.addButton} variant="contained" color="primary" onClick={handleAddSlot}>{t('slot.button_add')}</Button>
      <div>
        <h3>{t('slot.slot_times')}:</h3>
       
        <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t('slot.day')}</TableCell>
            <TableCell align="right">{t('slot.from')}</TableCell>
            <TableCell align="right">{t('slot.to')}</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSlots.map((slot, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
              {t(`day.${slot.day}`)} 
              </TableCell>
              <TableCell align="right">{slot.from}</TableCell>
              <TableCell align="right">{slot.to}</TableCell>
              <TableCell align="right"><DeleteIcon onClick={()=>handleRemoveSlot(index)}/></TableCell>
             
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
      </div>
    </div>
  );
};

export default AddTimeSlotsForm;