import { useNotify, usePermissions } from 'react-admin';
import React, { useState, useEffect } from 'react';
import { endOfMonth, startOfMonth, format } from 'date-fns';
import Grid from '@material-ui/core/Grid';
import { useTranslate } from 'ra-core';
import { Card, CardContent, TableFooter, CircularProgress } from '@material-ui/core';
import myDataProvider from '../../dataProvider';
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import "react-datepicker/dist/react-datepicker.css";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

registerLocale('es', es);

const Report = () => {

    const [from, setFrom] = useState(startOfMonth(new Date()));
    const [to, setTo] = useState(endOfMonth(new Date()));
    const [data, setData] = useState(null);
    const translate = useTranslate();
    const [loading, setLoading] = useState(false);
    const { permissions } = usePermissions();
    const notify = useNotify();

    useEffect(() => {

        fetchData();

      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [from, to]); 

    if( permissions && !permissions.includes('VIEW_REPORT') ) {
        return null;
    } 

    function fetchData() {
        setLoading(true);
        myDataProvider.getReport(`account/report`,{from: format(from, 'yyyy-MM-dd'), to: format(to, 'yyyy-MM-dd')})
            .then((response) => {
                setData(response.data);
            })
            .catch((e) => {
                notify('Error: loading data', { type: 'warning' })
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function handleMonth(data) {
        setFrom(new Date(data));
        setTo(endOfMonth(new Date(data)));
    }

    function handleDateFrom(data) {
        setFrom(new Date(data));
    }

    function handleDateTo(data) {
        setTo(new Date(data));
    }

      return(
        <>
         <Grid container spacing={3} style={{paddingTop: '40px', paddingBottom: '20px'}}>
            <Grid item xs={12} md={12} lg={12}>
            {translate('report.choose_month')}
            <DatePicker
                selected={new Date(from)}
                onChange={handleMonth}
                showIcon
                withPortal
                locale="es"
                dateFormat="dd-MM-yyyy"
                placeholderText={translate('report.month')}
                showMonthYearPicker
                showFullMonthYearPicker
                />
            </Grid>
        </Grid>
        <Grid container spacing={3} style={{paddingBottom: '40px'}}>
            <Grid item xs={12} md={2} lg={2}>
              {translate('report.from')} 
              <DatePicker selected={new Date(from)} onChange={handleDateFrom}
                locale="es"
                dateFormat="Pp"
                showIcon
                withPortal
                placeholderText={translate('report.from')} />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
            {translate('report.to')} 
            <DatePicker selected={new Date(to)} onChange={handleDateTo}
                locale="es"
                dateFormat="Pp"
                showIcon
                withPortal
                placeholderText={translate('report.to')} />
            </Grid>
        </Grid>
        {loading ? <CircularProgress
                      size={30}
                      thickness={2}
                    /> : <>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_income')}</h2>
            <Card>
                <CardContent>
                    <Table>
                        <TableBody>
                            {data && data.income && typeof data.income === 'object' && Object.keys(data.income).map((key, index) => (
                            <TableRow key={index}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{data.income[key]}</TableCell>
                                <TableCell>{data.interest[key]}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>{translate('report.total')}</TableCell>
                                <TableCell>{data && data.total && data.total.income}</TableCell>
                                <TableCell>{data && data.total && data.total.interest}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_outcome')}</h2>
            <Card>
                <CardContent>
                    <Table>
                        <TableBody>
                            {data && data.outcome && typeof data.outcome === 'object' && Object.keys(data.outcome).map((key, index) => (
                            <TableRow key={index}>
                                <TableCell>{key}</TableCell>
                                <TableCell>{data.outcome[key]}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>{translate('report.total')}</TableCell>
                                <TableCell>{data && data.total && data.total.outcome}</TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </CardContent>
            </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_balance')}</h2>
            <Card>
                <CardContent>
                    <Table>
                        <TableBody>
                            <TableRow >
                              <TableCell>{translate('report.last_balance')}</TableCell>
                               <TableCell>{data && data.total && data.total.last && data.total.last.balance} {data && data.total && data.total.last && format(new Date(data.total.last.createdAt), 'dd-MM-yyyy')}</TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{translate('report.total_income')}</TableCell>
                                <TableCell>{ data && data.total && data.total.income} </TableCell>
                            </TableRow>
                            <TableRow >
                                <TableCell>{translate('report.total_interest')}</TableCell>
                                <TableCell>{ data && data.total && data.total.interest} </TableCell>
                            </TableRow>
                            <TableRow>
                            <TableCell >{translate('report.total_outcome')}</TableCell>
                            <TableCell >{data && data.total && data.total.outcome}</TableCell>
                            </TableRow>
                            <TableRow >
                              <TableCell>{translate('report.difference')}</TableCell>
                               <TableCell>{data && data.total && data.total.balance}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_fixed')}</h2>
                <Card>
                    <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>{translate('report.date')}</TableCell>
                                <TableCell>{translate('report.amount')}</TableCell>
                                <TableCell>{translate('report.description')}</TableCell>
                                <TableCell>{translate('report.method')}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {data && data.account &&
                        data.account.fixed.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell>{format(new Date(item.impactedAt), 'dd-MM-yyyy H:mm')}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.paymentMethod.name}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>{translate('report.total')}</TableCell>
                                <TableCell>{data && data.total && data.total.fixed}</TableCell>
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_credit')}</h2>
                <Card>
                    <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>{translate('report.date')}</TableCell>
                                <TableCell>{translate('report.amount')}</TableCell>
                                <TableCell>{translate('report.description')}</TableCell>
                                <TableCell>{translate('report.method')}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {data && data.account &&
                        data.account.credit.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell>{format(new Date(item.impactedAt), 'dd-MM-yyyy H:mm')}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.paymentMethod.name}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>{translate('report.total')}</TableCell>
                                <TableCell>{data && data.total && data.total.credit}</TableCell>
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
            <h2>{translate('report.title_cashout')}</h2>
                <Card>
                    <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>{translate('report.date')}</TableCell>
                                <TableCell>{translate('report.amount')}</TableCell>
                                <TableCell>{translate('report.description')}</TableCell>
                                <TableCell>{translate('report.method')}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {data && data.account &&
                        data.account.cashout.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell>{format(new Date(item.impactedAt), 'dd-MM-yyyy H:mm')}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.paymentMethod.name}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>{translate('report.total')}</TableCell>
                                <TableCell>{ data && data.total && data.total.cashout}</TableCell>
                            </TableRow>
                        </TableFooter>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={6}>
            <h2>{translate('report.title_income_list')}</h2>
                <Card>
                    <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{translate('report.date')}</TableCell>
                                <TableCell>{translate('report.amount')}</TableCell>
                                <TableCell>{translate('report.interest')}</TableCell>
                                <TableCell>{translate('report.description')}</TableCell>
                                <TableCell>{translate('report.method')}</TableCell>
                                <TableCell>{translate('report.booking')}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {data && data.account &&
                        data.account.incomes.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell>{format(new Date(item.impactedAt), 'dd-MM-yyyy H:mm')}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.interest}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.paymentMethod.name}</TableCell>
                                <TableCell>{ item.booking && <a href={`./#/bookings/${item.booking.id}/show`} target="_blank" rel="noreferrer">{item.booking.id}</a>}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
            <h2>{translate('report.title_outcome_list')}</h2>
                <Card>
                    <CardContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>{translate('report.date')}</TableCell>
                                <TableCell>{translate('report.amount')}</TableCell>
                                <TableCell>{translate('report.description')}</TableCell>
                                <TableCell>{translate('report.method')}</TableCell>
                            </TableRow>
                            </TableHead>
                        <TableBody>
                        {data && data.account &&
                        data.account.outcomes.map((item, index) => (
                            <TableRow key={index} >
                                <TableCell>{format(new Date(item.impactedAt), 'dd-MM-yyyy H:mm')}</TableCell>
                                <TableCell>{item.amount}</TableCell>
                                <TableCell>{item.description}</TableCell>
                                <TableCell>{item.paymentMethod.name}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </Grid>
            
        </Grid>
        </>}

        </>
      )
};
export default Report;