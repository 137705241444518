import * as React from 'react';
import { RouteWithoutLayout } from 'react-admin';
import ResetPassword from '../resetPassword';
import ThemedCustomRouteNoLayout from '../layout/themedCustomRouteNoLayout';
import { Route } from 'react-router-dom';
import Report from '../components/Report';
import profile from '../components/Profile';
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <RouteWithoutLayout
    exact
    path="/forgot_password"
    component={ThemedCustomRouteNoLayout}
  />,
  <RouteWithoutLayout exact path="/reset_password" component={ResetPassword} />,
  <Route path="/report" exact component={Report} />,
  <Route key="my-profile" path="/profile"  component={profile.edit}/>
];
