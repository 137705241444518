import * as React from "react";
import { List, Datagrid, TextField, BooleanField, SimpleList } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate } from 'ra-core';
import { ListActions } from '../shared/ListActions';
import { BulkActions } from '../shared/BulkActions';

export const AgentList = ({ permissions, ...props }) => {
    const translate = useTranslate();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    if (permissions && !permissions.includes('VIEW_AGENTS')) {
      return null;
    }
   return (
     <List {...props} title={translate('agent.title')}
     actions={<ListActions showFilter={false} permissions={permissions} entity="AGENTS" {...props}/>}
     bulkActionButtons={<BulkActions permissions={permissions} entity="AGENTS" {...props}/>}>
       {isSmall ? (
         <SimpleList
           primaryText={(record) => `${record.alias}`}
           secondaryText={(record) => `${record.firstName} ${record.lastName}`}
           tertiaryText={(record) => record.phone }
         />
       ) : (
         <Datagrid rowClick="edit">
           <TextField source="id" />
           <TextField source="alias" label={translate('agent.alias')} />
           <TextField source="firstName" label={translate('agent.firstName')} />
           <TextField source="lastName" label={translate('agent.lastName')} />
           <TextField source="phone" label={translate('agent.phone')} />
           <TextField source="email" label={translate('agent.email')} />
           <BooleanField
             source="isActive"
             label={translate('agent.isActive')}
             sortable={false}
           />
         </Datagrid>
       )}
     </List>
   );

};